import React from 'react'

import { styled } from 'ui/styles'
import { breakpoints } from 'shared/dimensions'
import { RadioButton, Text } from 'ui/components'
import { css } from 'styled-components'

const Wrapper = styled.div<{ isInColumn?: boolean; isQuiz?: boolean }>`
  display: flex;
  flex-direction: ${({ isQuiz }) => (isQuiz ? 'column' : 'row')};
  justify-content: ${({ isInColumn }) => (isInColumn ? 'start' : 'space-between')};
  margin: 16px 0px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    flex-direction: column;
    padding-top: 5px;
  }
`

const StyledText = styled(Text)`
  min-width: 240px;
  width: 100%;

  @media (min-width: 240px) {
    min-width: 100px;
  }
`

const RadioWrapper = styled.div<{
  isAlwaysInRow?: boolean
  isAlwaysInColumn?: boolean
  isQuiz?: boolean
  isTakeAllSpace?: boolean
}>`
  display: flex;
  flex-direction: ${({ isAlwaysInColumn }) => (isAlwaysInColumn ? 'column' : 'row')};
  gap: 16px;

  ${({ isTakeAllSpace }) =>
    !isTakeAllSpace &&
    css`
      width: 100%;
    `};

  ${({ isQuiz }) =>
    isQuiz &&
    css`
      justify-content: center;
      margin-top: 24px;
    `};

  @media (max-width: ${breakpoints.mobile - 1}px) {
    flex-direction: ${({ isAlwaysInRow }) => (isAlwaysInRow ? 'row' : 'column')};
    padding-top: 8px;
  }
`

type RadioProps = {
  label: string
  initialChecked?: string
  onChange: (label: string | undefined, name: string) => void
  customLabels?: string[]
  isInColumn?: boolean
  isQuiz?: boolean
  isAlwaysInRow?: boolean
  isAlwaysInColumn?: boolean
  isTakeAllSpace?: boolean
}

export const RadioGroup: React.FC<RadioProps> = ({
  label,
  onChange,
  isInColumn,
  initialChecked,
  isQuiz,
  isAlwaysInRow,
  isAlwaysInColumn,
  isTakeAllSpace,
  customLabels = ['0', '1', '2', '3']
}) => {
  const initialIndex = customLabels.findIndex((el) => el === initialChecked)

  const [activeCheckedIndex, setActiveCheckedIndex] = React.useState(initialIndex)

  React.useEffect(() => {
    setActiveCheckedIndex(initialIndex)
  }, [initialIndex, label])

  const handleChange = (value: boolean, i: number, curLabel: string) => {
    setActiveCheckedIndex(value ? i : -1)
    onChange(value ? curLabel : undefined, label)
  }

  return (
    <Wrapper isInColumn={isInColumn} isQuiz={isQuiz}>
      {label && (
        <StyledText variant={isQuiz ? 'h5' : 'h7'} align={isQuiz ? 'center' : 'left'} isInColumn={isInColumn}>
          {label}
        </StyledText>
      )}
      <RadioWrapper
        isAlwaysInRow={isAlwaysInRow}
        isAlwaysInColumn={isAlwaysInColumn}
        isQuiz={isQuiz}
        isTakeAllSpace={isTakeAllSpace}
      >
        {customLabels.map((currentLabel, i) => (
          <RadioButton
            key={i}
            label={currentLabel}
            initialChecked={i === activeCheckedIndex}
            onChange={(value) => handleChange(value, i, currentLabel)}
          />
        ))}
      </RadioWrapper>
    </Wrapper>
  )
}
