import React from 'react'

import { styled } from 'ui/styles'
import { Text } from 'ui/components'
import { breakpoints, useDimensions } from 'shared/dimensions'

export const FormSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

const Wrapper = styled.div`
  margin-bottom: 32px;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    margin-bottom: 16px;
  }
`

export const SectionHeader: React.FC = ({ children }) => {
  const { isDesktop } = useDimensions()

  return (
    <Wrapper>
      <Text variant={isDesktop ? 'h5' : 't2'}>{children}</Text>
    </Wrapper>
  )
}
