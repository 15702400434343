import React from 'react'
import { useMutation } from 'react-query'
import HttpService from 'services/main.services'

export type DataFileT = {
  name: string
  uuid: string
}

interface IUpload {
  setFile: (value: React.SetStateAction<DataFileT[] | undefined>) => void
  files: FileList
}

export const useUploadFile = (setValue: (value: React.SetStateAction<string>) => void) => {
  const uploadFiles = async ({ setFile, files }: IUpload) => {
    setValue('Файлы загружаются...')
    setFile(undefined)
    const response = await HttpService.uploadFiles(files)

    if (response.data) {
      const result: DataFileT[] = response.data
      setValue('')
      setFile(result)
    }
  }

  const { isLoading, mutate, isError } = useMutation(uploadFiles, {
    onError: () => {
      setValue('Что-то пошло не так')
    }
  })

  return { isLoading, upload: mutate, isError }
}
