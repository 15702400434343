import React from 'react'

import { breakpoints } from 'shared/dimensions'
import { Icon, PrimaryButton, RedButton, RedSpan, Text } from 'ui/components'
import { styled, theme } from 'ui/styles'

import { QuizReturn } from '../hooks/useQuiz'
import { RadioGroup } from '../atoms/radio-group'

const Wrapper = styled.div`
  display: flex;
  padding: 32px 128px;
  border: 1px solid ${theme.palette.light_gray};
  max-width: 756px;
  margin: 0 auto;
  border-radius: 16px;
  justify-content: center;

  @media (max-width: ${breakpoints.desktop - 1}px) {
    width: 100%;
    padding: 32px 16px;
    margin: 0 auto;
    align-items: center;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${breakpoints.mobile - 1}px) {
    width: 100%;
  }
`

const SavedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CircleIcon = styled(Icon)<{ isSuccess: boolean }>`
  width: 94px;
  height: 94px;
  border-radius: 50%;
  background-color: ${theme.palette.dark_blue};
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  justify-content: center;
`

const ButtonsWrapper = styled.div`
  display: flex;
  margin: 16px 0 24px;
`

const Counter = styled.div`
  width: fit-content;
  align-self: end;
`

const BackButton = styled(PrimaryButton)`
  margin-right: 16px;
`

const ActiveStep = styled(RedSpan)`
  font-weight: bold;
`

const GraySpan = styled.span`
  color: ${theme.palette.blue_gray};
`

const Description = styled(Text)`
  color: ${theme.palette.blue_gray};
`

export const QuizSection: React.FC<QuizReturn> = ({
  STEPS,
  DESCRIPTION,
  onRadioChange,
  handleNext,
  handlePrev,
  curStep,
  radioValues,
  curRadioValue
}) => {
  return (
    <Wrapper>
      {curStep === STEPS.length ? (
        <SavedWrapper>
          <CircleIcon name="success" width={36} height={36} color={theme.palette.white} />
          <Text variant="t2">Благодарим за ответы</Text>
        </SavedWrapper>
      ) : (
        <ContentWrapper>
          <Counter>
            Шаг <ActiveStep>{curStep + 1}</ActiveStep>
            <GraySpan>/{STEPS.length}</GraySpan>
          </Counter>
          <RadioGroup
            initialChecked={radioValues[curStep]}
            label={STEPS[curStep]}
            onChange={onRadioChange}
            isInColumn
            isAlwaysInRow
            isQuiz
          />
          <ButtonsWrapper>
            {curStep !== 0 && (
              <BackButton width={104} onClick={handlePrev}>
                Назад
              </BackButton>
            )}
            <RedButton
              width={curStep === 0 ? 208 : 104}
              disabled={!radioValues[curStep] && !curRadioValue}
              onClick={handleNext}
            >
              Далее
            </RedButton>
          </ButtonsWrapper>
          <Description variant="t1" align="center">
            {DESCRIPTION}
          </Description>
        </ContentWrapper>
      )}
    </Wrapper>
  )
}
