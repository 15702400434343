import React from 'react'

import styled from 'styled-components'
import { breakpoints } from 'shared/dimensions'
import { theme } from 'ui/styles'
import { CloseButton } from '../molecules'
import { Text } from 'ui/components'

type ContactsBarProps = {
  onClose?(): void
}

const ContactBar = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 140px;

  background-color: ${theme.palette.white};
  padding: 28px 20px 24px;
  border-radius: 30px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  @media (min-width: ${breakpoints.mobile - 1}px) {
    display: none;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const BoldText = styled(Text)`
  font-weight: bold;
`

export const ContactsBar: React.FC<ContactsBarProps> = ({ onClose }) => {
  return (
    <ContactBar>
      <Header>
        <BoldText variant="t1" color={theme.palette.black}>
          ARGO
        </BoldText>
        <CloseButton onClose={onClose} />
      </Header>
      <Text variant="t1" color={theme.palette.black}>
        Ярославль, ул. Свободы, 71А, 4 этаж
      </Text>
      <Text variant="t1" color={theme.palette.blue_gray}>
        +7 800 101-39-36
      </Text>
    </ContactBar>
  )
}
