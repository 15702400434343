import React from 'react'
import { styled, theme } from 'ui/styles'
import { spin } from './keyframes'

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  width: 105%;
  height: 105%;
  z-index: 2;

  background: rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);

  /* for safari mobile */
  align-self: center;
  justify-content: center;
  align-items: center;
`

const LoadingCircle = styled.div`
  position: absolute;
  display: flex;
  width: 60px;
  height: 60px;
  z-index: 6;

  border-width: 4px;
  border-style: solid;
  border-color: ${theme.palette.pink_red} ${theme.palette.pink_red} ${theme.palette.pink_red} transparent;
  border-radius: 50%;

  animation: ${spin} ${theme.transition.spin}ms linear infinite;
  -webkit-animation: ${spin} ${theme.transition.spin}ms linear infinite;

  &:before,
  &:after {
    position: absolute;
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: ${theme.palette.pink_red};
    left: 4px;
  }

  &:before {
    top: 9%;
  }

  &:after {
    bottom: 9%;
  }
`
export const Loader: React.FC = () => (
  <Wrapper>
    <LoadingCircle />
  </Wrapper>
)
