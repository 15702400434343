/* eslint-disable no-useless-escape */
const regExps = {
  russianName: /^([А-Я][а-я]+\s?){1,3}$/,
  email:
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/,
  number: /^[1-9]\s?(\d\s?)*$/
}

export const validate = (value: string, regExp: keyof typeof regExps): boolean => regExps[regExp].test(value)
