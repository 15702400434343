import React from 'react'

import { styled, theme } from 'ui/styles'
import { Icon, Text } from 'ui/components'
import { breakpoints } from 'shared/dimensions'

const BREAKPOINT = 1600

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 291px;
  height: 108px;
  z-index: 2;
  cursor: pointer;

  padding-top: 28px;
  padding-left: 24px;
  border-radius: 16px;
  background: rgba(42, 61, 87, 0.86);
  box-shadow: 0px 10px 15px rgba(42, 61, 87, 0.5);
  backdrop-filter: blur(32px);

  transition-duration: ${theme.transition.hover}ms;
  transform: translateX(-20%) translateY(220%);

  &:hover {
    transform: translateX(-20%) translateY(210%);
    transition: transform ${theme.transition.hover}ms ease;
  }

  @media (max-width: ${BREAKPOINT}px) {
    transform: translateX(-10%) translateY(180%);

    &:hover {
      transform: translateX(-10%) translateY(170%);
    }
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    width: 196px;
    height: 64px;
    padding-top: 12px;
    padding-left: 24px;

    transform: translateX(-15%) translateY(220%);

    &:hover {
      transform: translateX(-15%) translateY(210%);
    }
  }
`

const ProjectName = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`

const StyledText = styled(Text)<{ isTitle: boolean }>`
  @media (max-width: ${breakpoints.tablet - 1}px) {
    font-size: ${({ isTitle }) => (isTitle ? '10px' : '14px')};
    line-height: ${({ isTitle }) => (isTitle ? '16px' : '18px')};
    gap: 0;
  }
`

const StyledIcon = styled(Icon)`
  transform: translateY(10%) rotateZ(45deg);

  @media (max-width: ${breakpoints.tablet - 1}px) {
    transform: translateY(-10%) rotateZ(45deg);
  }
`

type Props = {
  name: string
  onClick?: () => void
}

export const ProjectPopUp: React.FC<Props> = ({ name, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <StyledText variant="t3" color={theme.palette.blue_gray} isTitle>
        Проект
      </StyledText>
      <ProjectName>
        <StyledText variant="h1" color={theme.palette.white}>
          {name}
        </StyledText>
        <StyledIcon name="arrows/top" color={theme.palette.white}></StyledIcon>
      </ProjectName>
    </Wrapper>
  )
}
