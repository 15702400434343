import React from 'react'

import { TermsContent } from 'app/features/terms'

import { styled } from 'ui/styles'
import { Page } from 'app/templates'
import { Image, MobileUpperFrame } from 'ui/components'
import { useDimensions } from 'shared/dimensions'

const Wrapper = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`

const SingleImage = styled(Image)`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: -1;
`

const ImageFilter = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;

  background: rgba(42, 61, 87, 0.9);
  mix-blend-mode: normal;
  z-index: -1;
`

export const Terms: React.FC = () => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  const termsContent: JSX.Element = React.useMemo(
    () => (
      <Wrapper>
        <SingleImage name="career-bg.webp" width="100%" height="100%" fit="cover" />
        <ImageFilter />
        <TermsContent />
      </Wrapper>
    ),
    []
  )

  return (
    <Page>
      {isMobile ? <MobileUpperFrame openingParameter={isMobile}>{termsContent}</MobileUpperFrame> : termsContent}
    </Page>
  )
}
