import React from 'react'

import { getStyles, styled, theme } from 'ui/styles'
import { Icon, PhoneField, Text } from 'ui/components'
import { InputReturn } from 'shared/hooks/useInput'
import { breakpoints, useDimensions } from 'shared/dimensions'
import { useTextareaHeight } from 'shared/hooks'

const Wrapper = styled.div<{ isMobile: boolean }>`
  display: inline-flex;
  position: relative;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`

const StyledText = styled(Text)`
  min-width: 240px;
`

const StyledTextArea = styled.textarea<{ isMobile: boolean; isValid: boolean; isWithFile?: boolean }>`
  ${getStyles('t1')}
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 8px ${({ isWithFile }) => (isWithFile ? 42 : 16)}px 8px 16px;

  resize: none;

  border: ${({ isValid }) =>
    isValid ? `1px solid ${theme.palette.light_gray}` : `1px solid ${theme.palette.pink_red}`};

  color: ${({ isValid }) => (isValid ? theme.palette.dark_blue : theme.palette.pink_red)};
  transition-property: border, color;
  transition-duration: ${theme.transition.hover}ms;

  &::placeholder {
    text-overflow: ellipsis;
    color: ${theme.palette.blue_gray};
  }
`

const MultiFileSelect = styled.div<{ isMobile: boolean; isValid: boolean; isWithFile?: boolean }>`
  ${getStyles('t1')}
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 8px ${({ isWithFile }) => (isWithFile ? 42 : 16)}px 8px 16px;

  border-radius: 8px;
  border: ${({ isValid }) =>
    isValid ? `1px solid ${theme.palette.light_gray}` : `1px solid ${theme.palette.pink_red}`};
`

const FileItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  max-width: 150px;

  background-color: ${theme.palette.light_gray};
  color: ${theme.palette.blue_gray};
  border-radius: 2px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-bottom: 1px;

  :not(:last-child) {
    margin-right: 10px;
  }
`

const FileName = styled(Text)`
  text-overflow: ellipsis;
`

const RemoveFileButton = styled.div`
  position: absolute;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-color: ${theme.palette.light_gray};
  border-radius: 2px;
  right: 0;

  &:hover {
    background-color: ${theme.palette.dark_blue};
  }
`

const InputWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    height: 44px;
  }
`

const StyledInput = styled.input`
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  cursor: pointer;
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
`

type FormRowProps = {
  label: string
  input: InputReturn
  placeholder?: string
  isWithFile?: boolean
  isPdfFile?: boolean
}

export const FormRow: React.FC<FormRowProps> = ({ label, placeholder, input, isWithFile, isPdfFile }) => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null)

  useTextareaHeight(textareaRef, input.value)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      input.onFileLoad(event.target.files)
    }
  }

  return (
    <Wrapper isMobile={isMobile}>
      <StyledText variant={isMobile ? 't_12_20' : 't2'} color={input.isValid ? 'inherit' : theme.palette.pink_red}>
        {label}
      </StyledText>
      {input.isPhoneField ? (
        <PhoneField {...input} />
      ) : input.file && input.file.length ? (
        <MultiFileSelect isMobile={isMobile} isValid={input.isValid}>
          {Array.from(input.file).map((file, i) => (
            <FileItem key={i}>
              <FileName variant="t3">{file.name}</FileName>
              <RemoveFileButton onClick={() => input.removeFile(file.uuid)}>
                <Icon name="close" />
              </RemoveFileButton>
            </FileItem>
          ))}
        </MultiFileSelect>
      ) : (
        <StyledTextArea
          ref={textareaRef}
          isMobile={isMobile}
          spellCheck={false}
          placeholder={placeholder}
          rows={1}
          isWithFile={isWithFile}
          disabled={isPdfFile}
          {...input}
        />
      )}
      {isWithFile && (
        <InputWrapper>
          <StyledInput type="file" accept={isPdfFile ? 'application/pdf' : '*'} onChange={handleChange} multiple />
          <StyledIcon
            name="upload"
            color={input.file && input.file.length ? theme.palette.dark_blue : theme.palette.blue_gray}
          />
        </InputWrapper>
      )}
    </Wrapper>
  )
}
