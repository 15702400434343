import React from 'react'
import { styled, theme } from 'ui/styles'
import { Icon, Text } from '.'

const Wrapper = styled.div`
  margin: 32px 0;
`

const ActiveItem = styled.div`
  display: flex;
  gap: 8px;
`

const ItemsWrapper = styled.div`
  padding: 24px;
  background: ${theme.palette.white};
  box-shadow: 0px 16px 32px rgba(42, 61, 87, 0.16);
  border-radius: 16px;
`

const Item = styled(Text)`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

const StyledIcon = styled(Icon)<{ isOpen: boolean }>`
  transition: transform ${theme.transition.mainMenu}ms ease;
  transform: rotate(${({ isOpen }) => (isOpen ? 180 : 0)}deg);
`

type Option = { value: number; label: string }

type SelectProps = {
  options: Option[]
  initialSelectedValue?: number
  checkIfDisabled(option: Option): boolean
  onChange(option: Option): void
}

export const Select: React.FC<SelectProps> = ({ options, initialSelectedValue = -1, checkIfDisabled, onChange }) => {
  const [activeValue, setActiveValue] = React.useState(initialSelectedValue)
  const [isOpen, setIsOpen] = React.useState(false)

  const activeOption = React.useMemo(
    () => options.find((option) => option.value === activeValue),
    [options, activeValue]
  )

  const handleSelectClick = () => {
    setIsOpen((prev) => !prev)
  }

  const handleItemClick = (option: Option) => {
    const isDisabled = checkIfDisabled(option)
    if (isDisabled) {
      return
    }

    setActiveValue(option.value)
    setIsOpen(false)
    onChange(option)
  }

  return (
    <Wrapper>
      <ActiveItem onClick={handleSelectClick}>
        <Text variant="t2" color={theme.palette.pink_red}>
          {activeOption?.label}
        </Text>
        <StyledIcon name="arrows/top" color={theme.palette.pink_red} isOpen={isOpen} />
      </ActiveItem>

      {isOpen && (
        <ItemsWrapper>
          {options.map((option) => (
            <Item
              variant="t2"
              key={option.value}
              color={
                option.value === activeValue
                  ? theme.palette.pink_red
                  : checkIfDisabled(option)
                  ? theme.palette.blue_gray
                  : theme.palette.dark_blue
              }
              onClick={() => handleItemClick(option)}
            >
              {option.label}
            </Item>
          ))}
        </ItemsWrapper>
      )}
    </Wrapper>
  )
}
