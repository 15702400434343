import React from 'react'

import { styled } from 'ui/styles'
import { Page } from 'app/templates'
import { Slider } from 'app/features'
import { PROJECTS } from 'app/data/projects'
import { OutsourceOutstaffPopup } from 'app/features/modals/organisms/outsource-outstaff'
import { useDimensions } from 'shared/dimensions'
import { connect } from 'react-redux'
import { RootState } from 'root-reducer'
import { Dispatch } from 'redux'
import { setProject } from 'store/project/actions'
import { close } from 'store/project/popup/actions'

const ContentWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export type ContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const ProjectsContainer: React.FC<ContainerProps> = ({
  activeProjectIndex,
  setActiveProject,
  isPopupOpen,
  closePopup,
  activeSection
}) => {
  const { type } = useDimensions()

  return (
    <Page>
      {isPopupOpen && type !== 'mobile' ? (
        <OutsourceOutstaffPopup closePopup={closePopup} activeSection={activeSection} />
      ) : (
        <ContentWrapper>
          <Slider
            data={PROJECTS.map((el, i) => ({
              image: el.portfolio,
              title: el.title,
              chips: el.chips
            }))}
            setActiveProject={setActiveProject}
            activeProjectIndex={activeProjectIndex}
          />
        </ContentWrapper>
      )}
    </Page>
  )
}

const mapStateToProps = (state: RootState) => ({
  activeProjectIndex: state.project.activeProjectIndex,
  isPopupOpen: state.popup.isOpen,
  activeSection: state.popup.activeSection
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setActiveProject: (id: number) => dispatch(setProject(id)),
  closePopup: () => dispatch(close())
})

export const Projects = connect(mapStateToProps, mapDispatchToProps)(ProjectsContainer)
