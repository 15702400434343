import React from 'react'

import { DataFileT, useUploadFile } from './useUploadFile'

type InputProps = {
  initialValue?: string
  isNotRequired?: boolean
  isPhoneField?: boolean
  validate?(val: string): boolean
  formatOnBlur?(val: string): string
}

export type InputReturn = {
  value: string
  isValid: boolean
  onChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string): void
  onBlur(): boolean
  onFileLoad(file: FileList): void
  clearField(): void
  removeFile(fileUuid: string): void
  file?: DataFileT[]
  isPhoneField?: boolean
}

const defaultValidate = (val: string) => !!val

const fileType = (value: string): string => {
  const splitStr = value.split('.')
  return splitStr.slice(0, splitStr.length)[splitStr.length - 1]
}

const MAX_FILE_SIZE_MB = 25

const invalidFileFormats: string[] = [
  'exe',
  '0xe',
  'app',
  'apk',
  'bat',
  'ba_',
  'bin',
  'cgi',
  'cmd',
  'com',
  'csh',
  'e_e',
  'esh',
  'ex_',
  'exe1',
  'ezt',
  'isu',
  'nexe',
  'otm',
  'paf',
  'ps1',
  'qit',
  'rgs',
  'scr',
  'script',
  'vdo',
  'vexe',
  'ws',
  'wsf',
  'msi',
  'msu'
]

export const useInput: (props: InputProps) => InputReturn = ({
  initialValue = '',
  isPhoneField = false,
  isNotRequired,
  validate = isNotRequired ? () => true : defaultValidate,
  formatOnBlur
}) => {
  const [value, setValue] = React.useState<string>(initialValue)
  const [file, setFile] = React.useState<DataFileT[] | undefined>(undefined)
  const [isValid, setIsValid] = React.useState<boolean>(true)
  const [isChangingLocked, setIsChangingLocked] = React.useState<boolean>(!!file)

  const uploadingFiles = useUploadFile(setValue)

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (isChangingLocked) {
      return
    }

    if (!isValid || isNotRequired) {
      setIsValid(true)
    }

    const newVal = typeof e === 'string' ? e : e.target.value
    setValue(newVal)
  }

  const onBlur = () => {
    if (file) {
      return true
    } else {
      const isCurrentlyValid = validate(value) || !!(isNotRequired && !value.length)

      setValue((prev) => {
        const trimmed = prev.trim()
        return formatOnBlur ? formatOnBlur(trimmed) : trimmed
      })
      setIsValid(isCurrentlyValid)

      return isCurrentlyValid
    }
  }

  const onFileLoad = (files: FileList) => {
    if (!files.length) {
      return
    }
    const fileSize: number = Array.from(files)
      .map((val) => val.size)
      .reduce((prev, cur) => prev + cur)

    setValue('')
    setIsValid(true)
    setIsChangingLocked(true)

    const acceptedFiles = Array.from(files).filter((val) => !invalidFileFormats.includes(fileType(val.name)))

    if (fileSize < MAX_FILE_SIZE_MB * 1000000 && acceptedFiles.length === files.length) {
      uploadingFiles.upload({ setFile, files })
    } else {
      setValue('')
      setIsChangingLocked(false)
      setIsValid(false)
    }
  }

  const removeFile = (fileUuid: string) => {
    const filteredFiles = file?.filter((item) => item.uuid !== fileUuid)
    setFile(filteredFiles)

    if (!filteredFiles!.length) {
      setIsChangingLocked(false)
    }
  }

  const clearField = () => {
    setValue(initialValue)
    setFile(undefined)
  }

  return {
    value,
    file,
    isValid,
    isPhoneField,
    onChange,
    onBlur,
    onFileLoad,
    clearField,
    removeFile
  }
}
