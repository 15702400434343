import { styled, getStyles } from 'ui/styles'

import { Button, ButtonProps } from './button'

type Props = {
  width?: string | number
  bigText?: boolean
  big?: boolean
  disabled?: boolean
  inverted?: boolean
} & ButtonProps

export const RedButton = styled(Button)<Props>`
  ${({ bigText }) => (bigText ? getStyles('h2') : getStyles('t1'))}

  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ big }) => (big ? '16px 64px' : '8px 32px')};
  height: ${({ big }) => (big ? 56 : 40)}px;
  border-radius: 39px;
  background: ${({ inverted, theme }) => (inverted ? theme.palette.pink_red : 'inherit')};
  color: ${({ inverted, disabled, theme }) =>
    disabled ? theme.palette.blue_gray : inverted ? theme.palette.white : theme.palette.pink_red};
  border: ${({ inverted, disabled, theme }) =>
    `1px solid ${disabled ? theme.palette.blue_gray : inverted ? theme.palette.white : theme.palette.pink_red}`};
  transition-property: color, background-color;
  transition-duration: ${({ theme }) => theme.transition.hover}ms;
  ${({ width }) => width && `width: ${typeof width === 'number' ? `${width}px` : width}`};
  ${({ disabled }) => disabled && 'pointer-events: none'};
  white-space: nowrap;

  @media (hover: hover) {
    &:hover {
      background: ${({ theme, inverted }) => (inverted ? theme.palette.white : theme.palette.pink_red)};
      color: ${({ theme, inverted }) => (inverted ? theme.palette.pink_red : theme.palette.white)};
    }
  }
`
