export const TERMS = [
  {
    title: 'Термины и принятые сокращения',
    list: [
      'Персональные данные (ПД) — любая информация, относящаяся прямо или косвенно к определенному или определяемому физическому лицу (субъекту ПД).',
      'Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.',
      'Неавтоматизированная обработка персональных данных — обработка персональных данных без помощи средств вычислительной техники.',
      'Персональные данные, сделанные общедоступными субъектом персональных данных — персональные данные, доступ неограниченному кругу лиц к которым предоставлен субъектом персональных данных, либо по его просьбе.',
      'Cookies — это часть данных, автоматически располагающаяся на жестком диске компьютера при каждом посещении веб-сайта. Таким образом, cookies — это уникальный идентификатор браузера для веб-сайта. Cookies дают возможность хранить информацию на сервере и помогают легче ориентироваться в веб-пространстве, а также позволяют осуществлять анализ сайта и оценку результатов. Большинство веб-браузеров разрешают использование cookies, однако можно изменить настройки для отказа от работы с cookie или отслеживания пути их рассылки. При этом некоторые ресурсы могут работать некорректно, если работа cookies в браузере будет запрещена.',
      'IP-адрес — уникальный сетевой адрес узла в компьютерной сети, построенной на основе стека протоколов TCP/IP.',
      'Пользователь — пользователь сети Интернет.',
      'Сайт — любой из сайтов, в том числе https://argo-studio.com, сервисов, служб, программ ARGO.',
      'ARGO — организация, самостоятельно или совместно с другими лицами организующая и (или) осуществляющая обработку персональных данных, а также определяющая цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными. Юридическое лицо ООО «Код Ит Ап», юридический адрес: Россия, 150003, Ярославская обл., г. Ярославль, ул. Полушкина Роща, 9 / стр. 14, помещ. А4-55'
    ]
  },
  {
    title: 'Официальное уведомление',
    paragraphs: [
      'Данное официальное уведомление размещено в дополнение к прочим официальным уведомлениям и заявлениям об ответственности, находящимся на интернет-сайте ARGO, либо включенным в состав помещенных на этом сайте документов. ARGO оставляет за собой право вносить любые изменения в данный текст без предварительного оповещения пользователей сайта.'
    ]
  },
  {
    title: 'Общие положения',
    paragraphs: [
      'Настоящая Политика конфиденциальности (далее – Политика) составлена в соответствии с пунктом 2 статьи 18.1 Федерального закона «О персональных данных» №152-ФЗ от 27 июля 2006 г., а также иными нормативно-правовыми актами Российской Федерации в области защиты и обработки персональных данных и действует в отношении всех персональных данных, которые ARGO может получить от Пользователя во время использования им в сети Интернет Сайта. ARGO обеспечивает защиту обрабатываемых персональных данных от несанкционированного доступа и разглашения, неправомерного использования или утраты в соответствии с требованиями Федерального закона от 27 июля 2006 г. №152-ФЗ «О персональных данных».',
      'ARGO обязано опубликовать или иным образом обеспечить неограниченный доступ к настоящей Политике конфиденциальности в соответствии с ч. 2 ст. 18.1. ФЗ-152.'
    ]
  },
  {
    title: 'Авторские права',
    paragraphs: [
      'Информация сайта ARGO защищена законом об авторских правах. ARGO предоставляет право посетителям сайта использовать опубликованные материалы в любых личных и некоммерческих целях. За исключением оговоренных ситуаций, воспроизведение (копирование) любых материалов сайта невозможно без прямого письменного разрешения со стороны ARGO.'
    ]
  },
  {
    title: 'Лицензионные права',
    paragraphs: [
      'Информация, опубликованная на сайте ARGO, не может быть воспринята как предоставление лицензионных прав на интеллектуальную собственность, принадлежащую ARGO, либо третьим сторонам.'
    ]
  },
  {
    title: 'Торговые марки',
    paragraphs: [
      'Торговые марки, знаки, логотипы и т. п. (далее — Торговые марки), помещенные на данном сайте, являются собственностью ARGO и третьих сторон. Информация, опубликованная на сайте, не предоставляет никаких лицензионных прав на использование любых торговых марок без получения предварительного письменного согласия владельца.'
    ]
  },
  {
    title: 'Конфиденциальность',
    paragraphs: [
      'Мы обрабатываем персональные данные без использования средств автоматизации.',
      'Такие данные могут включать: имя, фамилию, адрес электронной почты, телефонный номер, возраст, образование. По вашему требованию информация в любой момент может быть удалена.',
      'Информация о посетителях сайта (IP-адрес, имя домена, тип браузера и операционная система, дата и время посещения и т. п.) собирается и сохраняется в целях ведения статистики посещений. Эта информация является общедоступной, таким образом, ARGO не несет ответственности за ее разглашение.',
      'Полученная информация может быть использована с целью оказания услуг в сфере разработки программного обеспечения и консультирования в этой области, регистрации заявок на проведение учебных стажировок и курсов обучения с возможностью последующего трудоустройства в компании, в маркетинговых целях, для рассылки новостей и информации об услугах; для персонализации сайта на основе истории поиска и просмотров пользователя. Полученные данные в обезличенном виде могут быть использованы для лучшего понимания потребностей бизнес-партнеров ARGO и улучшения качества сотрудничества.',
      'Мы будем посылать вам электронные и текстовые сообщения только в том случае, если вы дадите нам на это свое согласие. Вы можете в любое время попросить нас изменить или аннулировать ваши персональные данные в некоторых или во всех наших списках для рассылки. При возникновении затруднений при изменении или аннулировании персональных данных, вы можете обратиться по телефону компании ARGO +7 (999) 108-85-25 (Ярославль) или электронной почте office@codeitup.ru'
    ]
  },
  {
    title: 'Меры защиты персональных данных, используемые ARGO',
    paragraphs: [
      'Назначение лица, ответственного за обработку ПД, которое осуществляет организацию обработки ПД, обучение и инструктаж, внутренний контроль за соблюдением учреждением и его работниками требований к защите ПД.',
      'Определение актуальных угроз безопасности ПД при их обработке и разработка мер и мероприятий по защите ПД.',
      'Разработка политики конфиденциальности.',
      'Установление правил доступа к обрабатываемым ПД, а также обеспечение регистрации и учета всех действий, совершаемых с ПД.',
      'Установление индивидуальных паролей доступа сотрудников в информационную систему в соответствии с их производственными обязанностями.',
      'Применение средств защиты информации, прошедших в установленном порядке процедуру оценки соответствия.',
      'Сертифицированное антивирусное программное обеспечение с регулярно обновляемыми базами.',
      'Соблюдение условий, обеспечивающих сохранность ПД и исключающих несанкционированный к ним доступ.',
      'Обнаружение фактов несанкционированного доступа к персональным данным и принятие мер.',
      'Восстановление ПД, модифицированных или уничтоженных вследствие несанкционированного доступа к ним.',
      'Обучение работников ARGO, непосредственно осуществляющих обработку ПД, положениям законодательства РФ о ПД, в том числе требованиям к защите ПД, документам, определяющим политику «ARGO» в отношении обработки ПД, локальным актам по вопросам обработки персональных данных. Осуществление внутреннего контроля и аудита.'
    ]
  },
  {
    title: 'Ссылки',
    paragraphs: [
      'Ссылки на другие (внешние) сайты размещены на этом сайте бесплатно. Информация с этих сайтов не является продолжением, либо дополнением материалов ARGO.'
    ]
  },
  {
    title: 'Действие законодательства',
    paragraphs: [
      'Поддержка данного сайта осуществляется из офиса ARGO, расположенного в г. Ярославле, Российская Федерация. Все вопросы, касающиеся доступа пользователей к сайту и использования размещенной на нем информации, регулируются действующим законодательством Российской Федерации. Любые спорные вопросы, касающиеся использования сайта, должны рассматриваться в судебной инстанции соответствующей юрисдикции, а именно, в Арбитражном суде Ярославской области.'
    ]
  }
]
