import { InputReturn, useInput } from 'shared/hooks/useInput'
import { validate } from 'shared/lib/validation'

type TRow = {
  label: string
  input?: InputReturn
  placeholder?: string
  isOptional?: boolean
}

export type InputProps = Record<string, TRow>

const ROWS: InputProps = {
  lastName: {
    label: 'Фамилия*',
    placeholder: 'Константинов'
  },
  firstName: {
    label: 'Имя*',
    placeholder: 'Константин'
  },
  patronymic: {
    label: 'Отчество',
    placeholder: 'Константинович',
    isOptional: true
  },
  organization: {
    label: 'Компания',
    placeholder: 'Газпром',
    isOptional: true
  },
  email: {
    label: 'Email*',
    placeholder: 'konstantin@gazprom.ru'
  },
  phone: {
    label: 'Телефон*',
    placeholder: '+7 (900) 000-00-00'
  },
  description: {
    label: 'Опишите задание',
    placeholder: 'Требуется разработать мобильное приложение и web-кабинет для автоматизации бизнес-процессов компании',
    isOptional: true
  },
  budget: {
    label: 'Планируемый бюджет, ₽',
    placeholder: '300 000',
    isOptional: true
  }
}

const ROW_KEYS = Object.keys(ROWS)

export type ContactUsModalReturn = ReturnType<typeof useContactUsModal>

export type ContactUsSubmitT = Record<string, string>

export const useContactUsModal = () => {
  ROWS.lastName.input = useInput({
    initialValue: ROWS.lastName.input?.value,
    validate: (val) => validate(val, 'russianName')
  })
  ROWS.firstName.input = useInput({
    initialValue: ROWS.firstName.input?.value,
    validate: (val) => validate(val, 'russianName')
  })
  ROWS.patronymic.input = useInput({
    initialValue: ROWS.patronymic.input?.value,
    isNotRequired: ROWS.patronymic.isOptional
  })
  ROWS.organization.input = useInput({
    initialValue: ROWS.organization.input?.value,
    isNotRequired: ROWS.organization.isOptional
  })
  ROWS.email.input = useInput({ initialValue: ROWS.email.input?.value, validate: (val) => validate(val, 'email') })
  ROWS.phone.input = useInput({
    initialValue: ROWS.phone.input?.value,
    validate: (val) => validate(val, 'phone'),
    isPhoneField: true
  })
  ROWS.description.input = useInput({
    initialValue: ROWS.description.input?.value,
    isNotRequired: ROWS.description.isOptional
  })
  ROWS.budget.input = useInput({
    initialValue: ROWS.budget.input?.value,
    validate: (val) => validate(val, 'number'),
    isNotRequired: ROWS.budget.isOptional
  })

  const isInvalidLabelVisible = ROW_KEYS.every((row) => !ROWS[row].input?.isValid && !ROWS[row].isOptional)

  const isButtonEnabled: boolean = ROW_KEYS.every(
    (row) => ROWS[row].isOptional || (ROWS[row].input!.isValid && (ROWS[row].input!.value || ROWS[row].input!.file))
  )

  const handleSubmit = (): ContactUsSubmitT => {
    let isAllValid = true

    const values = ROW_KEYS.reduce<Record<string, string>>((result, rowKey) => {
      if (!ROWS[rowKey].isOptional && !ROWS[rowKey].input!.onBlur()) {
        isAllValid = false
        return result
      }

      if (ROWS[rowKey].input!.value) {
        result[rowKey] = ROWS[rowKey].input!.value
      }

      return result
    }, {})

    if (!isAllValid) {
      return {}
    }

    return values
  }

  return { rows: ROWS, rowKeys: ROW_KEYS, isInvalidLabelVisible, isButtonEnabled, handleSubmit }
}
