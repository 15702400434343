import React from 'react'

import { styled } from 'ui/styles'
import { Separator } from 'ui/components'

import { FormSection, SectionHeader } from '../atoms'
import { HardSkillsReturn } from '../hooks/useHardSkills'
import { WorkExperiencesSection } from './work-experience-section'
import { UsingToolsSection } from './using-tools-section'
import { PlatformTestingSection } from './platform-testing-section'
import { TestingExperienceSection } from './testing-experience-section'
import { QuizSection } from './quiz-section'
import { DevelopmentExperienceSection } from './development-experience-section'

const HardSkillRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const HardSkillSection: React.FC<HardSkillsReturn> = ({
  workExperiences,
  quiz,
  developmentExperience,
  usingTools,
  platformTesting,
  testingExperience
}) => (
  <FormSection>
    <SectionHeader>Опыт работы, хард-скиллы и инструментарий</SectionHeader>
    <HardSkillRow>
      <WorkExperiencesSection {...workExperiences} />
      <QuizSection {...quiz} />
      <DevelopmentExperienceSection {...developmentExperience} />
      <Separator verticalMargin={1} />
      <UsingToolsSection {...usingTools} />
      <Separator verticalMargin={1} />
      <PlatformTestingSection {...platformTesting} />
      <Separator verticalMargin={1} />
      <TestingExperienceSection {...testingExperience} />
      <Separator verticalMargin={1} />
    </HardSkillRow>
  </FormSection>
)
