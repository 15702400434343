import React from 'react'

import { getStyles, styled, theme } from 'ui/styles'
import { Text } from 'ui/components'
import { InputReturn } from 'shared/hooks/useInput'
import { breakpoints, useDimensions } from 'shared/dimensions'
import { useTextareaHeight } from 'shared/hooks'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 24px;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    flex-direction: column;
  }
`

const Label = styled.div`
  min-width: 240px;
  width: 100%;
  align-self: center;
  margin-right: 18px;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    align-self: start;
  }
`

const StyledTextArea = styled.textarea<{ isMobile: boolean; isValid: boolean }>`
  ${getStyles('t1')}
  align-self: start;
  width: 100%;
  border-radius: 8px;
  padding: 8px 16px 8px 16px;

  box-sizing: border-box;
  border: ${({ isValid }) =>
    isValid ? `1px solid ${theme.palette.light_gray}` : `1px solid ${theme.palette.pink_red}`};
  transition-property: border, color;
  transition-duration: ${theme.transition.hover}ms;

  &::placeholder {
    color: ${theme.palette.blue_gray};
  }
`

type FormRowProps = {
  label: string
  input: InputReturn
  placeholder?: string
}

export const QuestionnaireFormRow: React.FC<FormRowProps> = ({ label, input, placeholder }) => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  const rows = isMobile ? 1 : label.length < 50 ? 1 : 2

  const labelRef = React.useRef<any>(null)
  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null)

  useTextareaHeight(textareaRef, input.value)

  return (
    <Wrapper>
      <Label ref={labelRef}>
        <Text variant="h7" color={input.isValid ? 'inherit' : theme.palette.pink_red}>
          {label}
        </Text>
      </Label>
      <StyledTextArea
        ref={textareaRef}
        isMobile={isMobile}
        spellCheck={false}
        placeholder={placeholder}
        rows={rows}
        {...input}
      />
    </Wrapper>
  )
}
