import React from 'react'

import { styled, theme } from 'ui/styles'
import { RoundBulletLi, Text } from 'ui/components'
import { TProject } from 'app/data/projects'

const Wrapper = styled.div<{ isDisplayed: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  height: ${({ isDisplayed }) => (isDisplayed ? '200%' : '0')};

  transition-property: height;
  transition-duration: ${theme.transition.mainMenu}ms;
`

const ProjectSummary = styled(Text)<{ isDisplayed: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  opacity: ${({ isDisplayed }) => (isDisplayed ? '1' : '0')};

  transition: opacity ${theme.transition.hover}ms;
  transition-delay: ${({ isDisplayed }) => (isDisplayed ? '300' : '0')}ms;
`

const StyledList = styled.ul<{ isDisplayed: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 32px;
  opacity: ${({ isDisplayed }) => (isDisplayed ? '1' : '0')};

  transition: opacity ${theme.transition.hover}ms;
  transition-delay: ${({ isDisplayed }) => (isDisplayed ? '300' : '0')}ms;
`

const StyledListItem = styled(RoundBulletLi)`
  line-height: 20px;
  font-size: 14px;
  color: ${theme.palette.white};

  &::before {
    background-color: ${theme.palette.white};
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

type Props = {
  data: TProject
  isShow: boolean
}

export const ProjectDescription: React.FC<Props> = ({ data, isShow }) => (
  <Wrapper isDisplayed={isShow}>
    <ProjectSummary variant="t3" color={theme.palette.white} isDisplayed={isShow}>
      {isShow && data.description.summary}
    </ProjectSummary>

    <StyledList isDisplayed={isShow}>
      {isShow && data.description.list?.map((el, i) => <StyledListItem key={i}>{el}</StyledListItem>)}
    </StyledList>
  </Wrapper>
)
