import React from 'react'

import { styled, theme } from 'ui/styles'
import { Text } from 'ui/components'
import { FormRow } from 'app/features/modals/molecules/form-row'

import { SectionHeader } from '../atoms'
import { PersonalDataReturn } from '../hooks/usePersonalData'

export const FormSection = styled.section`
  display: flex;
  flex-direction: column;
`

const StyledText = styled(Text)`
  margin: 8px 0;
`

export const PersonalDataSection: React.FC<PersonalDataReturn> = ({
  textAreas,
  textAreasKeys,
  isInvalidLabelVisible
}) => {
  const formRowsItems = React.useMemo(
    () =>
      textAreasKeys.map((key) => (
        <FormRow
          key={key}
          label={textAreas[key].label}
          placeholder={textAreas[key].placeholder}
          input={textAreas[key].input!}
          {...textAreas[key].input!}
        />
      )),
    [textAreas, textAreasKeys]
  )

  return (
    <FormSection>
      <SectionHeader>Личные данные</SectionHeader>
      {formRowsItems}
      {isInvalidLabelVisible && (
        <StyledText variant="t1" color={theme.palette.pink_red}>
          *Выделенные поля заполнены неверно.
        </StyledText>
      )}
    </FormSection>
  )
}
