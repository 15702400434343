import React from 'react'

import { Page } from 'app/templates'

import { YandexMap } from 'app/features'

export const Contacts: React.FC = () => {
  return (
    <Page>
      <YandexMap />
    </Page>
  )
}
