import { useState } from 'react'
import { useInput } from 'shared/hooks'
import { InputProps } from './useSoftSkills'

export type PlatformTestingReturn = ReturnType<typeof usePlatformTesting>

export const usePlatformTesting = () => {
  const [radioValue, setRadioValue] = useState<{ [label: string]: string | undefined }>({ label: undefined })

  const onRadioChange = (label: string | undefined, name: string) => {
    setRadioValue((prev) => ({
      ...prev,
      [name]: label
    }))
  }

  const TEXTAREA_ROWS: InputProps = {
    ux: {
      label: 'Был опыт работы с другими инструментами и технологиями.',
      isOptional: true
    }
  }

  const RADIO_ROWS: InputProps = {
    mobile: {
      label: 'Mobile'
    },
    web: {
      label: 'Web'
    },
    desktop: {
      label: 'Desktop'
    }
  }

  const TEXTAREA_KEYS = Object.keys(TEXTAREA_ROWS)
  const RADIO_KEYS = Object.keys(RADIO_ROWS)

  TEXTAREA_ROWS.ux.input = useInput({
    initialValue: TEXTAREA_ROWS.ux.input?.value,
    isNotRequired: TEXTAREA_ROWS.ux.isOptional
  })

  const isAllValid = TEXTAREA_KEYS.every((rowKey) => TEXTAREA_ROWS[rowKey].input?.isValid)

  const getValues = () => {
    const values = RADIO_KEYS.reduce<Record<string, string | undefined>>((res, rowKey) => {
      res[RADIO_ROWS[rowKey].label] = radioValue[RADIO_ROWS[rowKey].label]
      return res
    }, {})

    values[TEXTAREA_ROWS.ux.label] = TEXTAREA_ROWS.ux.input?.value

    return values
  }

  return {
    radioButtons: RADIO_ROWS,
    radioButtonsKeys: RADIO_KEYS,
    textAreas: TEXTAREA_ROWS,
    textAreasKeys: TEXTAREA_KEYS,
    onRadioChange,
    isAllValid,
    getValues
  }
}
