import { getStyles, styled } from 'ui/styles'

export const LetterBulletLi = styled.li<{ counter?: string }>`
  margin-top: 8px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  &::before {
    ${getStyles('t2')};
    content: counter(${({ counter }) => (counter ? counter : 'list')}, upper-alpha) '. ';
    counter-increment: ${({ counter }) => (counter ? counter : 'list')};
    margin-right: 16px;
    display: block;
    align-self: flex-start;
  }
`
