import { styled } from 'ui/styles'
import { breakpoints } from 'shared/dimensions'

export const Separator = styled.div<{ verticalMargin?: number }>`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.light_gray};
  margin: ${({ verticalMargin }) => (verticalMargin ? verticalMargin : 24)}px 0;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    margin: ${({ verticalMargin }) => (verticalMargin ? verticalMargin / 2 : 16)}px 0;
  }
`
