import React from 'react'

import { Page } from 'app/templates'
import { paths } from 'shared/routing'
import { styled, theme } from 'ui/styles'
import { HBox, Icon, Text, Image, slideText, RedButton } from 'ui/components'
import { breakpoints, useDimensions } from 'shared/dimensions'
import { css } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  position: relative;
  height: 100%;
`

const BackgroundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
`

const BackgroundImage = styled(Image)<{ position: boolean }>`
  display: flex;

  ${({ position }) =>
    position &&
    css`
      object-position: left;
    `}
`

const BottomBox = styled.div`
  display: flex;
  height: 40%;
  width: 100%;
  background-color: ${theme.palette.light_gray};
`

const LogoLink = styled.a`
  display: block;
  width: fit-content;
  height: 100%;
  animation: ${slideText} ${theme.transition.slide}ms ease;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 64px;
  z-index: 2;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    width: 100%;
    margin: 32px;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  height: 100%;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    height: 50%;
    align-items: center;
  }
`

const StyledButton = styled(RedButton)`
  width: 200px;
  margin-top: 48px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    margin-top: 32px;
  }
`

export const NotFound: React.FC = () => {
  const { type } = useDimensions()
  const isNotDesktop = type === 'mobile' || type === 'tablet'

  return (
    <Page>
      <Wrapper>
        <BackgroundWrapper>
          {isNotDesktop && <HBox height={124} />}
          <BackgroundImage name="404.webp" width="100%" height="100%" fit="cover" position={isNotDesktop} />
          {isNotDesktop && <BottomBox />}
        </BackgroundWrapper>
        <Content>
          <LogoLink href={paths.projects}>
            <Icon name="logo" />
          </LogoLink>
          <InfoWrapper>
            <Text variant="h3">Что-то пошло не так</Text>
            <Text variant="t1">Извините страница не найдена</Text>
            <StyledButton href={paths.projects}>Вернуться</StyledButton>
          </InfoWrapper>
        </Content>
      </Wrapper>
    </Page>
  )
}
