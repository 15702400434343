import { useState } from 'react'
import { useInput } from 'shared/hooks'
import { InputProps } from './useHardSkills'

export type DevelopmentExperienceSectionReturn = ReturnType<typeof useDevelopmentExperience>

export const useDevelopmentExperience = () => {
  const [radioTestingValue, setRadioTestingValueValue] = useState<string | undefined>(undefined)
  const [radioValue, setRadioValue] = useState<{ [label: string]: string | undefined }>({ label: undefined })

  const RADIO_ROWS: InputProps = {
    java: {
      label: 'Java'
    },
    js: {
      label: 'JS'
    },
    python: {
      label: 'Python'
    }
  }

  const RADIO_LABEL = 'Составление тестовой документации (тест-кейсы, чек-листы, тест-планы, смоук-тесты)'

  const TEXTAREA_ROWS: InputProps = {
    thirdPartyOffers: {
      label: 'Если не указан твой язык программирования, то напиши его и оцени свои знания в нем от 0 до 3.',
      isOptional: true
    },
    higherEducation: {
      label: 'Укажи фреймворки, с которыми приходилось работать.',
      isOptional: true
    }
  }

  const TEXTAREA_KEYS = Object.keys(TEXTAREA_ROWS)
  const RADIO_KEYS = Object.keys(RADIO_ROWS)

  TEXTAREA_ROWS.thirdPartyOffers.input = useInput({
    initialValue: TEXTAREA_ROWS.thirdPartyOffers.input?.value,
    isNotRequired: TEXTAREA_ROWS.thirdPartyOffers.isOptional
  })
  TEXTAREA_ROWS.higherEducation.input = useInput({
    initialValue: TEXTAREA_ROWS.higherEducation.input?.value,
    isNotRequired: TEXTAREA_ROWS.higherEducation.isOptional
  })

  const onRadioChange = (label: string | undefined, name: string) => {
    setRadioValue((prev) => ({
      ...prev,
      [name]: label
    }))
  }

  const onRadioTestingChange = (value: string | undefined) => {
    setRadioTestingValueValue(value)
  }

  const isAllValid = TEXTAREA_KEYS.every((rowKey) => TEXTAREA_ROWS[rowKey].input?.isValid)

  const getValues = () => {
    const radioValues = RADIO_KEYS.reduce<Record<string, string | undefined>>((res, rowKey) => {
      res[RADIO_ROWS[rowKey].label] = radioValue[RADIO_ROWS[rowKey].label]
      return res
    }, {})
    const textAreaValues = TEXTAREA_KEYS.reduce<Record<string, string | boolean | undefined>>((res, rowKey) => {
      res[TEXTAREA_ROWS[rowKey].label] = TEXTAREA_ROWS[rowKey].input!.value
      return res
    }, {})

    radioValues[RADIO_LABEL] = radioTestingValue

    return [radioValues, textAreaValues]
  }

  return {
    radioButtons: RADIO_ROWS,
    radioButtonsKeys: RADIO_KEYS,
    textAreas: TEXTAREA_ROWS,
    textAreasKeys: TEXTAREA_KEYS,
    RADIO_LABEL,
    onRadioChange,
    onRadioTestingChange,
    isAllValid,
    getValues
  }
}
