import React from 'react'
import { Icon } from 'ui/components'
import { styled } from 'ui/styles'

const Wrapper = styled.a`
  display: flex;
  width: 24px;
  height: 24px;
`

type IconButtonProps = {
  iconName: string
  link: string
}

export const IconButton: React.FC<IconButtonProps> = ({ iconName, link }) => (
  <Wrapper href={link} target="_blank" rel="noopener">
    <Icon name={`contacts/${iconName}`} />
  </Wrapper>
)
