import { InputReturn } from 'shared/hooks/useInput'
import { useDevelopmentExperience } from './useDevelopmentExperience'
import { usePlatformTesting } from './usePlatformTesting'
import { useQuiz } from './useQuiz'
import { useTestingExperience } from './useTestingExperience'
import { useUsingTools } from './useUsingTools'
import { useWorkExperiences } from './useWorkExperience'

type TARow = {
  label: string
  input?: InputReturn
  isOptional?: boolean
}

export type InputProps = Record<string, TARow>

export type HardSkillsReturn = ReturnType<typeof useHardSkills>

export const useHardSkills = () => {
  const workExperiences = useWorkExperiences()
  const quiz = useQuiz()
  const developmentExperience = useDevelopmentExperience()
  const usingTools = useUsingTools()
  const platformTesting = usePlatformTesting()
  const testingExperience = useTestingExperience()

  const hardSkillsHooks = [workExperiences, quiz, developmentExperience, usingTools, platformTesting, testingExperience]

  const isSubmitDisabled = hardSkillsHooks.every((hook) => hook.isAllValid)

  const getValues = () => {
    return hardSkillsHooks.reduce((cur, hook) => {
      const values = hook.getValues()

      return {
        ...cur,
        ...values
      }
    }, {})
  }

  return {
    workExperiences,
    quiz,
    developmentExperience,
    usingTools,
    platformTesting,
    testingExperience,
    isSubmitDisabled,
    getValues
  }
}
