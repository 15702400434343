import React from 'react'
import { Button, Icon } from 'ui/components'
import { styled, theme } from 'ui/styles'

type CloseButtonProps = {
  onClose?(): void
}

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 30px;
  background-color: ${theme.palette.blue_gray};
`

export const CloseButton: React.FC<CloseButtonProps> = ({ onClose }) => (
  <Button onClick={onClose}>
    <StyledButton>
      <Icon name="close" color={theme.palette.white} />
    </StyledButton>
  </Button>
)
