export const OUTSOURCE_MAIN_STEPS = [
  {
    step: 'Предпроектная аналитика',
    subSteps: ['формирование системных требований', 'проектирование архитектуры', 'дизайн концепт']
  },
  { step: 'Разработка двухнедельными спринтами' },
  { step: 'Финальное предрелизное тестирование' },
  { step: 'Регулярные релизы' },
  { step: 'Поддержка и развитие продукта' }
]

export const OUTSOURCE_TEAM = [
  [
    { lead: 'Product Owner' },
    { lead: 'SA Lead', workers: [{ position: 'Analyst' }] },
    { lead: 'Art Director', workers: [{ position: 'Designer' }] },
    { lead: 'Support Manager', workers: [{ position: 'Support Engineer' }] }
  ],
  [
    { lead: 'Project Manager' },
    {
      lead: 'Tech Lead',
      workers: [
        { position: 'Software Architect' },
        { position: 'Backend Developer' },
        { position: 'Frontend Developer' },
        { position: 'React Native Developer' }
      ]
    }
  ],
  [{ lead: 'Account manager' }, { lead: 'QA Lead', workers: [{ position: 'QA Engineer' }, { position: 'Tester' }] }]
]

export const OUTSOURCE_TEAM_ADAPTIVE = [
  [
    { lead: 'Product Owner' },
    { lead: 'SA Lead', workers: [{ position: 'Analyst' }] },
    { lead: 'Art Director', workers: [{ position: 'Designer' }] },
    { lead: 'QA Lead', workers: [{ position: 'QA Engineer' }, { position: 'Tester' }] },
    { lead: 'Support Manager', workers: [{ position: 'Support Engineer' }] }
  ],

  [
    { lead: 'Account manager' },
    { lead: 'Project Manager' },
    {
      lead: 'Tech Lead',
      workers: [
        { position: 'Software Architect' },
        { position: 'Backend Developer' },
        { position: 'Frontend Developer' },
        { position: 'React Native Developer' }
      ]
    }
  ]
]

export const OUTSTAFF_MAIN_STEPS = [
  { step: 'Подключаемся к проекту заказчика' },
  { step: 'В процессе погружения в проект, проводим аудит процессов заказчика' },
  { step: 'Помогаем выстроить разработку' },
  { step: 'Усиливаем текущую команду' },
  { step: 'Делимся экспертизой и успешными практиками' },
  { step: 'Поддерживаем и развиваем продукт' }
]

export const OUTSTAFF_TEAM = [
  [{ lead: 'Account manager' }, { lead: 'SA Lead', workers: [{ position: 'Analyst' }] }],

  [
    {
      lead: 'Tech Lead',
      workers: [
        { position: 'Software Architect' },
        { position: 'Backend Developer' },
        { position: 'Frontend Developer' },
        { position: 'React Native Developer' }
      ]
    }
  ],

  [{ lead: 'QA Lead', workers: [{ position: 'QA Engineer' }, { position: 'Tester' }] }]
]

export const OUTSTAFF_TEAM_ADAPTIVE = [
  [
    { lead: 'Account manager' },
    {
      lead: 'Tech Lead',
      workers: [
        { position: 'Software Architect' },
        { position: 'Backend Developer' },
        { position: 'Frontend Developer' },
        { position: 'React Native Developer' }
      ]
    }
  ],

  [
    { lead: 'SA Lead', workers: [{ position: 'Analyst' }] },
    { lead: 'QA Lead', workers: [{ position: 'QA Engineer' }, { position: 'Tester' }] }
  ]
]
