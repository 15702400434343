import React, { useMemo } from 'react'
import { CSSTransition } from 'react-transition-group'

import { styled, theme } from 'ui/styles'
import { Image, Text } from 'ui/components'
import { PROJECTS } from 'app/data/projects'
import { ProjectDescription } from 'app/features/slider/molecules/project-description'
import { breakpoints } from 'shared/dimensions'

import { Slide } from '../organisms/slider'

const HeightBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const ChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  margin-bottom: 64px;

  transition: opacity ${theme.transition.hover}ms linear;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    max-width: 400px;
    justify-content: start;
  }
`

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;

  width: 100%;
  height: 100%;
  z-index: 2;
  overflow-y: scroll;
`

export const SingleImage = styled(Image)<{ isDisplayed: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ isDisplayed }) => (isDisplayed ? '1' : '0')};
  transition: opacity 300ms, transform 500ms ease-in-out;
`

const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  align-items: center;

  @media (max-height: 400px) {
    height: auto;
  }
`

const Title = styled(Text)`
  margin: 32px 0;
`

const Chip = styled.div`
  display: flex;
  padding: 8px 16px;
  margin-bottom: 8px;
  height: 32px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 25px;
  align-items: center;

  :not(:last-child) {
    margin-right: 8px;
  }
`

type Props = {
  data: Slide[]
  onePerSlide?: boolean
  initialIndex?: number
  onSlideChange?(i: number): void
  isDisplayed: boolean
  curIndex: number
  slide: Slide
  isShowDescription: boolean
  transitionTimeout: number
  activeProjectIndex: number
}

export const ProjectSlide: React.FC<Props> = ({
  data,
  isDisplayed,
  curIndex,
  slide,
  isShowDescription,
  transitionTimeout,
  activeProjectIndex
}) => {
  const jsxChips = useMemo(
    () =>
      Boolean(data[curIndex].chips?.length) && (
        <ChipsWrapper>
          {data[curIndex].chips?.map((chipName) => (
            <Chip key={chipName}>
              <Text variant="t_12_20" color={theme.palette.white}>
                {chipName}
              </Text>
            </Chip>
          ))}
        </ChipsWrapper>
      ),
    [data, curIndex]
  )

  return (
    <CSSTransition in={isShowDescription} timeout={transitionTimeout} classNames="popup">
      <Wrapper>
        <SingleImage isDisplayed={isDisplayed} name={slide.image} width="100%" height="100%" fit="cover" />
        <ContentWrapper>
          <DescriptionWrapper>
            <HeightBox />
            <Title variant="h1" color={theme.palette.white} align="center">
              {data[curIndex].title?.toUpperCase()}
            </Title>
            <ProjectDescription
              isShow={isShowDescription}
              data={PROJECTS[activeProjectIndex === -1 ? 0 : activeProjectIndex]}
            />
          </DescriptionWrapper>
          {jsxChips}
        </ContentWrapper>
      </Wrapper>
    </CSSTransition>
  )
}
