import { useHardSkills } from './useHardSkills'
import { useOrganizationIssues } from './useOrganizationIssues'
import { usePersonalData } from './usePersonalData'
import { useSoftSkills } from './useSoftSkills'

export const useStudentForm = () => {
  const personalData = usePersonalData()
  const hardSkills = useHardSkills()
  const softSkills = useSoftSkills()
  const organizationIssues = useOrganizationIssues()

  const isSubmitDisabled = !personalData.isAllValid

  const handleSubmitClick = () => {
    const personalDataValues = personalData.getValues()
    const hardSkillsValues = hardSkills.getValues()
    const softSkillsValues = softSkills.getValues()
    const organizationIssuesValues = organizationIssues.getValues()

    const result = {
      personalData: { ...personalDataValues },
      hardSkills: { ...hardSkillsValues },
      softSkills: { ...softSkillsValues },
      organizationIssues: { ...organizationIssuesValues }
    }
    // tslint:disable-next-line: no-console
    console.log(result)
  }

  return {
    personalData,
    hardSkills,
    softSkills,
    organizationIssues,
    isSubmitDisabled,
    handleSubmitClick
  }
}
