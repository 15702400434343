import { useState } from 'react'
import { useInput } from 'shared/hooks'
import { InputReturn } from 'shared/hooks/useInput'

type TARow = {
  label: string
  input?: InputReturn
  placeholder?: string
  isOptional?: boolean
}

export type InputProps = Record<string, TARow>

export type SoftSkillsReturn = ReturnType<typeof useSoftSkills>

export const useSoftSkills = () => {
  const [radioValue, setRadioValue] = useState<{ [value: string]: string | undefined }>({ value: undefined })

  const onRadioChange = (value: string | undefined, name: string) => {
    setRadioValue((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const TEXTAREA_ROWS: InputProps = {
    mentoring: {
      label: 'Есть ли у тебя опыт менторинга, проведения стажировок?',
      isOptional: true
    },
    personalQualities: {
      label: 'Какие личностные качества тебе помогают быть эффективным сотрудником?',
      isOptional: true
    },
    interests: {
      label: 'Какие проекты или задачи тебе были бы интересны?',
      isOptional: true
    }
  }

  const RADIO_ROWS: InputProps = {
    grade: {
      label:
        'Как бы ты оценил(а) сейчас свой профессиональный уровень: junior / middle / senior? Если считаешь, что находишься на стыке двух грейдов, можешь ставить «+», например «junior +»'
    }
  }

  const customRadioLabels = ['junior', 'junior+', 'middle', 'middle+', 'senior']

  const TEXTAREA_KEYS = Object.keys(TEXTAREA_ROWS)
  const RADIO_KEYS = Object.keys(RADIO_ROWS)

  TEXTAREA_ROWS.mentoring.input = useInput({
    initialValue: TEXTAREA_ROWS.mentoring.input?.value,
    isNotRequired: TEXTAREA_ROWS.mentoring.isOptional
  })
  TEXTAREA_ROWS.personalQualities.input = useInput({
    initialValue: TEXTAREA_ROWS.personalQualities.input?.value,
    isNotRequired: TEXTAREA_ROWS.personalQualities.isOptional
  })
  TEXTAREA_ROWS.interests.input = useInput({
    initialValue: TEXTAREA_ROWS.interests.input?.value,
    isNotRequired: TEXTAREA_ROWS.interests.isOptional
  })

  const isAllValid = TEXTAREA_KEYS.every((rowKey) => TEXTAREA_ROWS[rowKey].input?.isValid)

  const getValues = () => {
    const values = TEXTAREA_KEYS.reduce<Record<string, string | undefined>>((res, rowKey) => {
      res[TEXTAREA_ROWS[rowKey].label] = TEXTAREA_ROWS[rowKey].input?.value
      return res
    }, {})

    values[RADIO_ROWS.grade.label] = radioValue[RADIO_ROWS.grade.label]

    return values
  }

  return {
    radioButtons: RADIO_ROWS,
    radioButtonsKeys: RADIO_KEYS,
    textAreas: TEXTAREA_ROWS,
    textAreasKeys: TEXTAREA_KEYS,
    radioLabels: customRadioLabels,
    onRadioChange,
    isAllValid,
    getValues
  }
}
