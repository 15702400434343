export type TProject = {
  name: string
  portfolio: string
  title: string
  description: { summary: string; list: string[] }
  chips?: string[]
}

export const PROJECTS: TProject[] = [
  {
    name: 'forward',
    portfolio: 'portfolio/forward.webp',
    title: 'Forward Leasing',
    description: {
      summary: 'Мобильное приложения для оформления товаров в лизинг',
      list: [
        'Аудит мобильного приложения',
        'Переработка фичей на основе фидбека от пользователей',
        'Разработка UI/UX мобильного приложения',
        'Разработка backend и frontend мобильного приложения'
      ]
    },
    chips: ['Проектирование', 'Аналитика', 'Frontend', 'Backend']
  },
  {
    name: 'forward-pay',
    portfolio: 'portfolio/forward-pay.webp',
    title: 'Forward pay',
    description: {
      summary: 'Мобильное приложение для покупки товаров в ритейле',
      list: [
        'Аудит мобильного приложения',
        'Доработка и рефакторинг сервиса после другой команды разработки',
        'Разработка backend и frontend мобильного приложения',
        'Комплексное тестирование МП'
      ]
    },
    chips: ['Аналитика', 'Frontend', 'Backend']
  },
  {
    name: 'tbi',
    portfolio: 'portfolio/tbi.webp',
    title: 'TBI',
    description: {
      summary: 'Web сервис для получения кредита',
      list: [
        'Цифровой банк с широким спектром онлайн- и мобильных решений',
        'Учтена специфика европейских банковских операций',
        'Разработка UI и основных функций сервиса',
        'Доработка и создание форм калькулятора онлайн выдачи кредитов'
      ]
    },
    chips: ['Проектирование', 'Аналитика', 'Frontend', 'Backend']
  },
  {
    name: 'otkritie',
    portfolio: 'portfolio/otkritie.webp',
    title: 'Открытие',
    description: {
      summary: 'Тестирование функций мобильного приложения банка «Открытие»',
      list: [
        'Оповещения о задолженностях по штрафам и налогам',
        ' Управления программой лояльности для получения кэшбэка с покупок',
        'Платежи и переводы по дебетовым картам',
        'Идентификация пользователей',
        'Курсы обмена валют',
        'Доработка веб-форм в личном кабинете сотрудника банка'
      ]
    },
    chips: ['Аудит', 'Тестирование']
  },
  {
    name: 'mozen',
    portfolio: 'portfolio/mozen.webp',
    title: 'Mozen',
    description: {
      summary: 'Разработка ERP системы для сервиса автоматизации выплат водителям от агрегаторов такси',
      list: [
        'Доработки существующего функционала личного кабинета по управлению таксопарком',
        'Разработка backend панели администратора для работы с данными и бизнес-процессами приложения для выплат водителям такси'
      ]
    },
    chips: ['Проектирование', 'Аналитика', 'Frontend', 'Backend']
  }
]
