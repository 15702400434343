import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import { styled, theme } from 'ui/styles'
import { breakpoints } from 'shared/dimensions'

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 50px 0;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, #2a3d57 0%, rgba(42, 61, 87, 0) 173.84%);
  background-attachment: fixed;
  background-repeat: no-repeat;

  z-index: 9999;
  overflow: auto;

  @media (max-width: ${breakpoints.desktop - 1}px) {
    padding: 64px 0;
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    overflow-y: hidden;
    padding: 16px 0 0 0;
  }
`

const Content = styled.div<{ maxWidth: string }>`
  position: relative;
  max-width: ${({ maxWidth }) => maxWidth};
  background-color: ${theme.palette.white};
  box-shadow: 0px 16px 32px rgba(42, 61, 87, 0.16);
  border-radius: 16px;
  padding: 32px 32px 40px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: scale(0);
  transition: opacity 300ms linear, transform 300ms ease;

  &.open {
    opacity: 1;
    transform: scale(1);
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    position: absolute !important;
    bottom: 0 !important;
    max-width: none;
    width: 100vw;
    border-radius: 0;
    padding: 16px 16px 24px !important;
    height: 100%;
    overflow-y: auto;
    opacity: 1;
    transform: translateY(100%);

    &.open {
      transform: translateY(0);
    }
  }
`

type Props = {
  isVisible: boolean
  onOverlayClick?: () => void
  contentMaxWidth?: string
}

export const ModalWindow: React.FC<Props> = ({ isVisible, contentMaxWidth = '90%', onOverlayClick, children }) => {
  const portal = document.getElementById('modal')
  const root = document.getElementById('root')
  const body = document.body

  const [isOpening, setIsOpening] = useState(isVisible)

  const isOpen = React.useMemo(() => !!portal && isVisible, [portal, isVisible])

  useEffect(() => {
    setIsOpening(isVisible)
  }, [isVisible])

  useEffect(() => {
    if (isOpen) {
      root?.classList.add('blur')
      body.classList.add('overflow-hidden')
    } else {
      root?.classList.remove('blur')
      body.classList.remove('overflow-hidden')
    }
  }, [isOpen, root, body])

  if (!isOpen) {
    return null
  }

  return (
    <>
      {ReactDOM.createPortal(
        <Wrapper onClick={onOverlayClick}>
          <Content
            maxWidth={contentMaxWidth}
            className={isOpening ? 'open' : ''}
            onClick={(ev) => {
              ev.stopPropagation()
            }}
          >
            {children}
          </Content>
        </Wrapper>,
        portal!
      )}
    </>
  )
}
