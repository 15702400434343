import { useInput } from 'shared/hooks'
import { InputReturn } from 'shared/hooks/useInput'
import { validate } from 'shared/lib/validation'

export type PersonalDataReturn = ReturnType<typeof usePersonalData>

export const usePersonalData = () => {
  type TRow = {
    label: string
    input?: InputReturn
    placeholder?: string
    isOptional?: boolean
  }

  type InputProps = Record<string, TRow>

  const TEXTAREA_ROWS: InputProps = {
    surname: {
      label: 'Фамилия*',
      placeholder: 'Константинов'
    },
    name: {
      label: 'Имя*',
      placeholder: 'Константин'
    },
    patronymic: {
      label: 'Отчество',
      placeholder: 'Константинович',
      isOptional: true
    },
    email: {
      label: 'Email*',
      placeholder: 'email@mail.ru'
    },
    phone: {
      label: 'Телефон*',
      placeholder: '+7 900 000-00-00'
    }
  }

  const TEXTAREA_KEYS = Object.keys(TEXTAREA_ROWS)

  TEXTAREA_ROWS.surname.input = useInput({
    initialValue: TEXTAREA_ROWS.surname.input?.value,
    validate: (val) => validate(val, 'russianName')
  })
  TEXTAREA_ROWS.name.input = useInput({
    initialValue: TEXTAREA_ROWS.name.input?.value,
    validate: (val) => validate(val, 'russianName')
  })
  TEXTAREA_ROWS.patronymic.input = useInput({
    initialValue: TEXTAREA_ROWS.patronymic.input?.value,
    isNotRequired: TEXTAREA_ROWS.patronymic.isOptional
  })
  TEXTAREA_ROWS.email.input = useInput({
    initialValue: TEXTAREA_ROWS.email.input?.value,
    validate: (val) => validate(val, 'email')
  })
  TEXTAREA_ROWS.phone.input = useInput({
    initialValue: TEXTAREA_ROWS.phone.input?.value,
    validate: (val) => validate(val, 'phone'),
    isPhoneField: true
  })

  const isAllValid =
    TEXTAREA_KEYS.every((rowKey) => TEXTAREA_ROWS[rowKey].input?.isValid) &&
    TEXTAREA_KEYS.some((rowKey) => TEXTAREA_ROWS[rowKey].input?.value)

  const isInvalidLabelVisible = TEXTAREA_KEYS.some((rowKey) => !TEXTAREA_ROWS[rowKey].input?.isValid)

  const getValues = () => {
    const values = TEXTAREA_KEYS.reduce<Record<string, string>>((res, rowKey) => {
      res[TEXTAREA_ROWS[rowKey].label] = TEXTAREA_ROWS[rowKey].input!.value
      return res
    }, {})

    return values
  }

  return {
    textAreas: TEXTAREA_ROWS,
    textAreasKeys: TEXTAREA_KEYS,
    isAllValid,
    isInvalidLabelVisible,
    getValues
  }
}
