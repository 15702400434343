import React from 'react'

import { useDimensions } from 'shared/dimensions'
import { InputReturn } from 'shared/hooks/useInput'
import { Text } from 'ui/components'
import { getStyles, styled, theme } from 'ui/styles'

import { RadioGroup } from './radio-group'

const BREAKPOINT = 1000

const Wrapper = styled.div<{ isMobile: boolean }>`
  display: inline-flex;
  position: relative;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`

const StyledText = styled(Text)`
  min-width: 240px;
  width: 100%;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media (max-width: ${BREAKPOINT}px) {
    flex-direction: column;
    align-items: start;
  }
`

const StyledTextArea = styled.input<{ isWithFile?: boolean }>`
  ${getStyles('t1')}
  width: 100%;
  height: 42px;
  border-radius: 8px;
  padding: 8px ${({ isWithFile }) => (isWithFile ? 42 : 16)}px 8px 16px;
  margin-right: 36px;

  border: 1px solid ${theme.palette.light_gray};

  color: ${theme.palette.dark_blue};
  transition-property: border, color;
  transition-duration: ${theme.transition.hover}ms;

  &::placeholder {
    color: ${theme.palette.blue_gray};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`

type RowProps = {
  label: string
  input: InputReturn
  onChange: (label: string | undefined, name: string) => void
}

export const CurrencyPickerRow: React.FC<RowProps> = ({ label, input, onChange }) => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  const onCurrencyPicker = (radioGroupLabel: string | undefined, name: string) => {
    const valueWithCurrency: string = `${input.value} ${label}`

    onChange(valueWithCurrency, name)
  }

  const currenciesLabels = ['Рубли', 'Доллары', 'Евро']

  return (
    <Wrapper isMobile={isMobile}>
      <StyledText variant="h7" color="inherit">
        {label}
      </StyledText>
      <InputWrapper>
        <StyledTextArea spellCheck={false} type="number" {...input} />
        <RadioGroup
          label=""
          onChange={onCurrencyPicker}
          customLabels={currenciesLabels}
          initialChecked={'Рубли'}
          isAlwaysInRow
        />
      </InputWrapper>
    </Wrapper>
  )
}
