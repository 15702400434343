import React from 'react'

import { styled } from 'ui/styles'
import { Text } from 'ui/components'
import { breakpoints } from 'shared/dimensions'
import { QuestionnaireFormRow } from '../atoms/form-row'
import { DevelopmentExperienceSectionReturn } from '../hooks/useDevelopmentExperience'
import { RadioGroup } from '../atoms/radio-group'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const RadioGroupWrapper = styled.div`
  width: 50%;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    width: 100%;
  }
`

export const DevelopmentExperienceSection: React.FC<DevelopmentExperienceSectionReturn> = ({
  radioButtonsKeys,
  radioButtons,
  textAreas,
  textAreasKeys,
  onRadioChange,
  onRadioTestingChange,
  RADIO_LABEL
}) => (
  <Wrapper>
    <RadioGroupWrapper>
      <Text variant="h7">С какими языками программирования работал?</Text>
      {radioButtonsKeys.map((key, i) => (
        <RadioGroup key={key} label={radioButtons[key].label} onChange={onRadioChange} isAlwaysInRow />
      ))}
    </RadioGroupWrapper>

    {textAreasKeys.map((key, i) => (
      <QuestionnaireFormRow key={key} label={textAreas[key].label} input={textAreas[key].input!} />
    ))}
    <RadioGroup label={RADIO_LABEL} onChange={onRadioTestingChange} isAlwaysInRow isTakeAllSpace />
  </Wrapper>
)
