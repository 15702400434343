import React from 'react'

import { styled } from 'ui/styles'
import { Link, RedButton, Separator, Text } from 'ui/components'
import { breakpoints, useDimensions } from 'shared/dimensions'
import { Modal } from 'app/features/modals/templates'
import { paths } from 'shared/routing'

import { HardSkillSection, OrganizationalIssuesSection, PersonalDataSection, SoftSkillsSection } from '../molecules'
import { useStudentForm } from '../hooks/useStudentForm'

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 32px;
  }
`

const StyledText = styled(Text)`
  max-width: 327px;
  margin: 0 0 0 32px;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    margin: 12px 0 0 0;
    flex-direction: column;
    justify-content: center;
  }
`

const Header: React.FC = () => <Text variant="h2" children={'Анкета соискателя'} />

type QuestionnaireModalFormProps = {
  onClose: () => void
}

export const QuestionnaireModalForm: React.FC<QuestionnaireModalFormProps> = ({ onClose }) => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  const { personalData, hardSkills, softSkills, organizationIssues, isSubmitDisabled, handleSubmitClick } =
    useStudentForm()

  return (
    <Modal onClose={onClose} header={<Header />}>
      <StyledForm>
        <PersonalDataSection {...personalData} />
        <HardSkillSection {...hardSkills} />
        <SoftSkillsSection {...softSkills} />
        <OrganizationalIssuesSection {...organizationIssues} />
      </StyledForm>

      {!isMobile && <Separator verticalMargin={24} />}

      <Footer>
        <RedButton big onClick={handleSubmitClick} disabled={isSubmitDisabled}>
          Отправить
        </RedButton>
        <StyledText variant="t1" align={isMobile ? 'center' : 'left'}>
          Нажимая на кнопку, вы соглашаетесь с&nbsp;
          <Link href={paths.terms} target="_blank">
            Обработкой персональных данных
          </Link>
        </StyledText>
      </Footer>
    </Modal>
  )
}
