import React from 'react'

import { styled, theme } from 'ui/styles'
import { Text } from 'ui/components'

const Wrapper = styled.div`
  display: flex;
`

const MainList = styled.ul`
  display: flex;
  flex: 1 0;
  align-items: start;
  flex-direction: column;
  flex-wrap: wrap;
`

const MemberLi = styled.li`
  position: relative;
  color: ${theme.palette.white};

  :not(:last-child) {
    margin-bottom: 24px;
  }

  div {
    white-space: nowrap;
  }
`

const WorkersList = styled.ul`
  width: 100%;
  margin-top: 8px;
`

const WorkerLi = styled.li`
  :not(:last-child) {
    margin-bottom: 8px;
  }

  div {
    white-space: nowrap;
  }
`

type TeamMember = { lead: string; workers?: { position: string }[] }

type Props = {
  team: TeamMember[][]
}

export const Team: React.FC<Props> = ({ team }) => {
  return (
    <Wrapper>
      {team.map((col, i) => (
        <MainList key={i}>
          {col.map((member, j) => (
            <MemberLi key={`${i}${j}`}>
              <Text variant="t3">{member.lead}</Text>
              {member.workers && (
                <WorkersList>
                  {member.workers.map((worker, k) => (
                    <WorkerLi key={`${i}${j}${k}`}>
                      <Text variant="t3" color={theme.palette.blue_gray}>
                        {worker.position}
                      </Text>
                    </WorkerLi>
                  ))}
                </WorkersList>
              )}
            </MemberLi>
          ))}
        </MainList>
      ))}
    </Wrapper>
  )
}
