import React from 'react'
import { useDimensions } from 'shared/dimensions'
import { styled, theme } from 'ui/styles'

type MobileUpperFrameProps = {
  openingParameter: boolean
}

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  overflow-y: auto;
  z-index: 99;
  opacity: 0;

  transition: transform ${theme.transition.hover}ms ease;
  transform: translateY(100vh);

  &.open {
    transform: translateY(0);
    opacity: 1;
  }
`

export const MobileUpperFrame: React.FC<MobileUpperFrameProps> = ({ openingParameter, children }) => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  const [isOpening, setIsOpening] = React.useState(openingParameter)

  const isOpen = React.useMemo(() => openingParameter && isMobile, [openingParameter, isMobile])

  React.useEffect(() => {
    setIsOpening(openingParameter)
  }, [openingParameter])

  if (!isOpen) {
    return null
  }

  return <Wrapper className={isOpening ? 'open' : ''}>{children}</Wrapper>
}
