import React from 'react'

import { VacancyT } from 'app/data/vacancy'
import { Section } from '.'

type SectionsProps = {
  data: VacancyT
}

export const Sections: React.FC<SectionsProps> = ({ data }) => (
  <>
    {data.tasks && <Section title="Задачи, которые предстоит решать:" list={data.tasks} />}
    {data.approach && <Section title="Ты нам подходишь, если:" list={data.approach} />}
    {data.expect && <Section title="Ждем от тебя:" list={data.expect} />}
    {data.skills && <Section title="Будет круто, если у тебя есть:" list={data.skills} />}
    {data.desirable && <Section title="Желательно знать:" list={data.desirable} />}
    {data.conditions && <Section title="Условия:" list={data.conditions} />}
  </>
)
