import { useState } from 'react'
export type QuizReturn = ReturnType<typeof useQuiz>

export const useQuiz = () => {
  const STEPS = [
    'Charles / Fiddler /Proxyman',
    'Postman',
    'SoapUI',
    'Swagger',
    'Chrome DevTools',
    'Android Studio',
    'XCode ',
    'Написание UI автотестов',
    'Maven / Gradle',
    'Qase / Test Rail / TestIT / Allure',
    'Написание Web UI автотестов',
    'Docker',
    'Написание API автотестов',
    'Написание мобильных UI автотестов'
  ]

  const DESCRIPTION = '0 – не работал; 1 – низкий уровень; 2 – базовый уровень; 3 – выше базового уровня'

  const SKILL_LABELS = ['Не работал', 'Низкий уровень', 'Базовый уровень', 'Выше базового уровня']

  const [curStep, setCurStep] = useState(0)
  const [radioValues, setRadioValues] = useState<string[]>([])
  const [curRadioValue, setCurRadioValue] = useState<string | undefined>(undefined)

  const onRadioChange = (label?: string) => {
    setCurRadioValue(label)
  }

  const handleNext = () => {
    if (curRadioValue) {
      setRadioValues((prev) => [...prev, curRadioValue])
      setCurStep((prev) => prev + 1)
      setCurRadioValue(undefined)
    } else if (radioValues[curStep]) {
      setCurStep((prev) => prev + 1)
    }
  }

  const handlePrev = () => {
    setCurStep((prev) => prev - 1)
  }

  const isAllValid = curStep === STEPS.length

  const getValues = () => {
    const values = radioValues.reduce<Record<string, string | undefined>>((res, rowKey, i) => {
      res[STEPS[i]] = SKILL_LABELS[+rowKey]
      return res
    }, {})

    return { quiz: values }
  }
  return {
    STEPS,
    DESCRIPTION,
    onRadioChange,
    handleNext,
    handlePrev,
    isAllValid,
    getValues,
    curStep,
    radioValues,
    curRadioValue
  }
}
