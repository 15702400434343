import { styled } from 'ui/styles'
import { QuestionnaireFormRow } from '../atoms/form-row'
import { RadioGroup } from '../atoms/radio-group'
import { TestingExperienceReturn } from '../hooks/useTestingExperience'

const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
`

export const TestingExperienceSection: React.FC<TestingExperienceReturn> = ({
  radioButtons,
  radioButtonsKeys,
  textAreas,
  textAreasKeys,
  radioLabels,
  onRadioChange
}) => {
  return (
    <Wrapper>
      {radioButtonsKeys.map((key) => (
        <RadioGroup key={key} label={radioButtons[key].label} onChange={onRadioChange} customLabels={radioLabels} />
      ))}
      {textAreasKeys.map((key) => (
        <QuestionnaireFormRow key={key} label={textAreas[key].label} input={textAreas[key].input!} />
      ))}
    </Wrapper>
  )
}
