import React from 'react'
import { useMutation } from 'react-query'
import { AxiosResponse } from 'axios'

import HttpService, { RequestModel } from 'services/main.services'
import { ContactUsSubmitT, VacancySubmitT } from 'app/features/modals/hooks'

export type SendRequestReturnT = ReturnType<typeof useSendRequest>

export const useSendRequest = (onSubmit: () => void, isVacancy: boolean = false) => {
  const [queryResult, setQueryResult] = React.useState<string | undefined>(undefined)

  const {
    isLoading,
    mutate: sendRequest,
    isError
  } = useMutation<AxiosResponse<RequestModel, any> | undefined, Error, VacancySubmitT>(
    async (data: VacancySubmitT) => {
      setQueryResult(undefined)

      if (isVacancy) {
        return await HttpService.createCandidateApplication(data)
      } else {
        return await HttpService.createFeedbackApplication(data as ContactUsSubmitT)
      }
    },
    {
      onSuccess: (res) => {
        onSubmit()
        setQueryResult(res!.data.id)
      },
      onError: (err: Error) => {
        setQueryResult(err.message)
      }
    }
  )

  return { queryResult, isLoading, sendRequest, isError }
}
