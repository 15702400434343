import { combineReducers } from 'redux'
import { connectRouter, RouterAction, RouterState } from 'connected-react-router'
import { History } from 'history'

import { StatusAction, statusReducer, StatusState } from './store/status/reducer'
import { TagAction, tagReducer, TagState } from './store/tag/reducer'
import { PopupAction, popupReducer, PopupState } from './store/project/popup/reducer'
import { projectReducer, ProjectState, ProjectAction } from './store/project/reducer'

export type RootState = {
  router: RouterState
  project: ProjectState
  popup: PopupState
  tag: TagState
  statusModal: StatusState
}

export const createRootReducer = (history: History) =>
  combineReducers<RootState>({
    router: connectRouter(history),
    project: projectReducer,
    popup: popupReducer,
    tag: tagReducer,
    statusModal: statusReducer
  })

export type RootAction = RouterAction | ProjectAction | PopupAction | TagAction | StatusAction
