import { theme } from 'ui/styles'

export const RoundButtonProps = {
  bgColor: {
    original: 'rgba(255, 255, 255, 0.25)',
    hover: 'rgba(255, 255, 255, 0.5)'
  },
  iconColor: {
    original: theme.palette.white,
    hover: theme.palette.white
  },
  iconName: 'close'
}

export const ModalButtonProps = {
  bgColor: {
    original: theme.palette.white,
    hover: theme.palette.light_gray
  },
  iconColor: {
    original: theme.palette.pink_red,
    hover: theme.palette.dark_blue
  },
  borderColor: {
    original: theme.palette.light_gray,
    hover: theme.palette.light_gray
  },
  iconName: 'close'
}
