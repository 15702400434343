import React, { createRef } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { styled } from 'ui/styles'
import { Icon, showContentWithOpacity, slideText, Text, UnderlineTab } from 'ui/components'
import { paths } from 'shared/routing'
import { breakpoints } from 'shared/dimensions'

import * as content from '../molecules'

const BREAKPOINT_2K = 2560

const NAV_ITEMS = [
  { name: 'Проекты', link: paths.projects, content: content.Projects },
  { name: 'Карьера', link: paths.career, content: content.Career },
  { name: 'Контакты', link: paths.contacts, content: content.Contacts },
  { link: paths.terms, content: content.Terms },
  { link: paths.widget, content: ()=><div/> }
]

const REFS: React.RefObject<HTMLAnchorElement>[] = NAV_ITEMS.map((_) => createRef())

const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;
  width: 430px;
  height: 100%;
  overflow-y: auto;

  box-shadow: 12px 4px 64px rgba(0, 0, 0, 0.25);
  animation: ${showContentWithOpacity} ${({ theme }) => theme.transition.slide}ms ease;
  @media (max-width: ${breakpoints.tablet - 1}px) {
    width: 366px;
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    width: 100%;
  }

  @media (min-width: ${BREAKPOINT_2K - 1}px) {
    width: 630px;
  }
`

const LogoLink = styled(Link)`
  display: block;
  width: fit-content;
  animation: ${slideText} ${({ theme }) => theme.transition.slide}ms ease;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 48px 64px 0 64px;

  transition: padding ${({ theme }) => theme.transition.hover}ms ease;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding: 48px 32px 0 32px;
  }
`

const Menu = styled.ul<{ underlineWidth: number; underlineTranslate: number }>`
  position: relative;
  display: flex;
  margin-top: 48px;

  &::after {
    content: '';
    position: absolute;
    display: block;
    bottom: -1px;
    height: 4px;
    left: 0;
    transform: translateX(${({ underlineTranslate }) => underlineTranslate}px);
    width: ${({ underlineWidth }) => underlineWidth}px;
    background: ${({ theme }) => theme.palette.dark_pink_red};
    transition: all ${({ theme }) => theme.transition.hover}ms linear;
  }
`

const Tab = styled.li`
  padding: 0 0 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.light_gray};

  :not(:last-child) {
    margin-right: 32px;
  }
`

export const Sidebar: React.FC = () => {
  const location = useLocation()

  let activeIndex = NAV_ITEMS.findIndex((el) => el.link === location.pathname)

  if (activeIndex > 2) {
    activeIndex = 0
  }
  if (activeIndex === -1) {
    return null
  }

  const ActiveContent = NAV_ITEMS[activeIndex].content

  let translate = 0
  for (let i = 0; i < activeIndex; i++) {
    translate += (REFS[i].current?.clientWidth || 74) + 32
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <LogoLink to={paths.projects}>
          <Icon name="logo" />
        </LogoLink>
        <Menu underlineWidth={REFS[activeIndex].current?.clientWidth || 74} underlineTranslate={translate}>
          {NAV_ITEMS.map(
            (el, i) =>
              el.name && (
                <Tab key={el.name}>
                  <UnderlineTab
                    to={el.link}
                    forwardRef={REFS[i]}
                    active={el.link === location.pathname}
                    firstRender={true}
                    isLink
                  >
                    <Text variant="t1">{el.name}</Text>
                  </UnderlineTab>
                </Tab>
              )
          )}
        </Menu>

        <ActiveContent />
      </ContentWrapper>
    </Wrapper>
  )
}
