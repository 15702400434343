import React from 'react'

import { styled } from 'ui/styles'

import { Button, ButtonProps } from './button'
import { Icon } from '.'

type Color = { original: string; hover: string }

export type RoundButtonProps = {
  bgColor: Color
  iconColor: Color
  iconName: string
  borderColor?: Color
} & ButtonProps

const Wrapper = styled(Button)<RoundButtonProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor.original};
  border: 1px solid ${({ borderColor }) => (borderColor ? borderColor.original : 'transparent')};
  transition: all ${({ theme }) => theme.transition.hover}ms linear;
  & svg * {
    transition: stroke ${({ theme }) => theme.transition.hover}ms linear;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${({ bgColor }) => bgColor.hover};
      border: 1px solid ${({ borderColor }) => (borderColor ? borderColor.hover : 'transparent')};
      & svg * {
        stroke: ${({ iconColor }) => iconColor.hover};
      }
    }
  }
`

export const RoundButton: React.FC<RoundButtonProps> = (props) => (
  <Wrapper {...props}>
    <Icon name={props.iconName} width={24} height={24} color={props.iconColor.original} />
  </Wrapper>
)
