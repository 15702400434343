import React from 'react'

import { breakpoints } from 'shared/dimensions'
import { styled } from 'ui/styles'

import { UsingToolsReturn } from '../hooks/useUsingTools'
import { RadioGroup } from '../atoms/radio-group'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    flex-direction: column;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const UsingToolsSection: React.FC<UsingToolsReturn> = ({ radioButtons, radioButtonsKeys, onRadioChange }) => {
  const buttonLength: number = radioButtonsKeys.length

  return (
    <Wrapper>
      <ContentWrapper>
        {radioButtonsKeys.map((key, i) =>
          i < buttonLength / 2 ? (
            <RadioGroup key={i} label={radioButtons[key].label} onChange={onRadioChange} isAlwaysInRow />
          ) : null
        )}
      </ContentWrapper>
      <ContentWrapper>
        {radioButtonsKeys.map((key, i) =>
          i >= buttonLength / 2 ? (
            <RadioGroup key={i} label={radioButtons[key].label} onChange={onRadioChange} isAlwaysInRow isTakeAllSpace />
          ) : null
        )}
      </ContentWrapper>
    </Wrapper>
  )
}
