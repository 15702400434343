import { useState } from 'react'
import { useInput } from 'shared/hooks'
import { InputProps } from './useHardSkills'

export type WorkExperienceReturn = ReturnType<typeof useWorkExperiences>

export const useWorkExperiences = () => {
  const [checkboxValue, setCheckboxValue] = useState(false)

  const CHECKBOX_LABEL = 'Работаешь в настоящее время?'

  const TEXTAREA_ROWS: InputProps = {
    thirdPartyOffers: {
      label: 'Если да, то почему рассматриваешь сторонние предложения? Что не устраивает на текущем месте?',
      isOptional: true
    },
    higherEducation: {
      label: 'Есть ли у тебя высшее образование и по какой специальности?',
      isOptional: true
    },
    experience: {
      label: 'Как давно работаешь в сфере тестирования?',
      isOptional: true
    }
  }

  const TEXTAREA_KEYS = Object.keys(TEXTAREA_ROWS)

  TEXTAREA_ROWS.thirdPartyOffers.input = useInput({
    initialValue: TEXTAREA_ROWS.thirdPartyOffers.input?.value,
    isNotRequired: TEXTAREA_ROWS.thirdPartyOffers.isOptional
  })
  TEXTAREA_ROWS.higherEducation.input = useInput({
    initialValue: TEXTAREA_ROWS.higherEducation.input?.value,
    isNotRequired: TEXTAREA_ROWS.higherEducation.isOptional
  })
  TEXTAREA_ROWS.experience.input = useInput({
    initialValue: TEXTAREA_ROWS.experience.input?.value,
    isNotRequired: TEXTAREA_ROWS.experience.isOptional
  })

  const onCheckboxChange = (value: boolean) => {
    setCheckboxValue(value)
  }

  const isAllValid = TEXTAREA_KEYS.every((rowKey) => TEXTAREA_ROWS[rowKey].input?.isValid)

  const getValues = () => {
    const values = TEXTAREA_KEYS.reduce<Record<string, string | boolean | undefined>>((res, rowKey) => {
      res[TEXTAREA_ROWS[rowKey].label] = TEXTAREA_ROWS[rowKey].input!.value
      return res
    }, {})

    values[CHECKBOX_LABEL] = checkboxValue

    return values
  }

  return {
    checkboxValue,
    onCheckboxChange,
    textAreas: TEXTAREA_ROWS,
    textAreasKeys: TEXTAREA_KEYS,
    CHECKBOX_LABEL,
    isAllValid,
    getValues
  }
}
