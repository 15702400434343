import React from 'react'

import { styled } from 'ui/styles'
import { Checkbox, Text } from 'ui/components'
import { breakpoints, useDimensions } from 'shared/dimensions'
import { QuestionnaireFormRow } from '../atoms/form-row'
import { WorkExperienceReturn } from '../hooks/useWorkExperience'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const WorkCheckbox = styled.div`
  flex-direction: row;
  display: flex;
  align-items: start;
  width: 100%;
  margin-bottom: 16px;
`

const StyledText = styled(Text)`
  min-width: 240px;
  width: 100%;

  @media (min-width: ${breakpoints.mobile - 1}px) {
    min-width: 100px;
  }
`

const CheckboxWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: start;

  ${({ isMobile }) => (isMobile ? '' : 'width: 100%')};
`

export const WorkExperiencesSection: React.FC<WorkExperienceReturn> = ({
  textAreas,
  textAreasKeys,
  CHECKBOX_LABEL,
  checkboxValue,
  onCheckboxChange
}) => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  return (
    <Wrapper>
      <WorkCheckbox>
        <StyledText variant="h7">{CHECKBOX_LABEL}</StyledText>
        <CheckboxWrapper isMobile={isMobile}>
          <Checkbox value={checkboxValue} onChange={onCheckboxChange} />
        </CheckboxWrapper>
      </WorkCheckbox>
      {textAreasKeys.map((key, i) => (
        <QuestionnaireFormRow key={key} label={textAreas[key].label} input={textAreas[key].input!} />
      ))}
    </Wrapper>
  )
}
