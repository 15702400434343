import React from 'react'

import { styled, theme } from 'ui/styles'
import { Icon, RedButton, Text } from 'ui/components'

import { Modal } from '../templates'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 56px 0 36px;
`

const CircleIcon = styled(Icon)<{ isSuccess: boolean }>`
  width: 94px;
  height: 94px;
  border-radius: 50%;
  background-color: ${({ isSuccess }) => (isSuccess ? theme.palette.dark_blue : theme.palette.blue_gray)};
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  justify-content: center;
`

type Props = {
  isSuccess: boolean
  onClose(): void
}

export const StatusModal: React.FC<Props> = ({ isSuccess, onClose }) => (
  <Modal onClose={onClose}>
    <Wrapper>
      <ContentWrapper>
        <CircleIcon
          name={isSuccess ? 'success' : 'close'}
          isSuccess={isSuccess}
          width={48}
          height={48}
          color={theme.palette.white}
        />
        <Text variant="h5" align="center">
          {isSuccess ? 'Спасибо!' : 'Упс!'}
          <br />
          {isSuccess ? 'Мы скоро свяжемся с вами' : 'Что-то пошло не так '}
        </Text>
      </ContentWrapper>
      <RedButton big onClick={onClose}>
        Хорошо
      </RedButton>
    </Wrapper>
  </Modal>
)
