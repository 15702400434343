import React from 'react'

import { styled } from 'ui/styles'
import { breakpoints } from 'shared/dimensions'
import { ProjectSlideMobile } from '../molecules/project-slide-mobile'
import { Slide } from './slider'

const Images = styled.div`
  display: flex;
  width: calc(100% + 32px);
  height: 138px;
  margin-bottom: 12px;
  padding-right: 64px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding: 0 32px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

const FixScrollAlign = styled.div`
  position: relative;
  width: 100%;
  padding: 69px 290px;
  touch-action: none;
`

type Props = {
  data: Slide[]
}

export const SliderMobile: React.FC<Props> = ({ data }) => (
  <Images>
    {data.map((slide, i) => (
      <ProjectSlideMobile key={i} slide={slide} />
    ))}
    <FixScrollAlign />
  </Images>
)
