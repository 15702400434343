import React from 'react'

import { ModalWindow } from 'shared/modal-window'
import { QuestionnaireModalForm } from 'app/features/questionnaire-modal-form'
import { StatusModal, VacancyModal } from 'app/features/modals'
import { styled, theme } from 'ui/styles'
import { RoundButton, Text, RoundButtonProps, showContentWithOpacity } from 'ui/components'
import { breakpoints } from 'shared/dimensions'
import { TagT, VacancyT } from 'app/data/vacancy'

import { Sections } from '../molecules'
import { ResponseButton } from '../atoms'
import { setStatusModalVisible } from 'store/status/actions'
import { RootState } from 'root-reducer'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 64px 60px;
  overflow: auto;
  backdrop-filter: blur(12px);

  animation: ${showContentWithOpacity} ${theme.transition.hover}ms linear;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding: 60px 32px 32px 32px;
  }
`

const CloseVacancy = styled(RoundButton)`
  position: absolute;
  z-index: 3;
  top: 32px;
  right: 32px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    top: 20px;
    right: 20px;
  }
`

const InformationWrapper = styled.div`
  display: flex;
  max-width: 700px;
  width: 100%;
  flex-direction: column;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    justify-content: start;
  }
`

const StyledText = styled(Text)`
  width: 90%;
  min-width: 200px;
  margin-bottom: 32px;
`
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    flex-direction: column;
    align-items: center;
  }
`

type CardProps = {
  vacancy: VacancyT
  vacancyKey: TagT | undefined
  isDisplayed: boolean
  onResponseClick(): void
}

export const VacancyCardContainer: React.FC<ContainerProps> = ({
  vacancy,
  isDisplayed,
  onResponseClick,
  setStatusVisible,
  activeStatus
}) => {
  const [cardIsDisplayed, setCardIsDisplayed] = React.useState(isDisplayed)

  const [responseModalVisible, setResponseModalVisible] = React.useState(false)
  const [questionnaireModalVisible, setQuestionnaireModalVisible] = React.useState(false)

  React.useEffect(() => {
    setCardIsDisplayed(isDisplayed)
  }, [isDisplayed])

  const handleOverlayClick = React.useCallback(() => {
    setResponseModalVisible(false)
    setStatusVisible(false)
    setQuestionnaireModalVisible(false)
  }, [setStatusVisible])

  const handleSubmitVacancy = React.useCallback(() => {
    setResponseModalVisible(false)
    setStatusVisible(false)
  }, [setStatusVisible])

  if (!cardIsDisplayed) {
    return null
  }

  return (
    <Wrapper>
      <InformationWrapper>
        <CloseVacancy {...RoundButtonProps} iconName="close" onClick={onResponseClick} />
        <StyledText variant="h4" color={theme.palette.white}>
          {vacancy.title}
        </StyledText>
        <Sections data={vacancy} />
        <ButtonWrapper>
          {/* <ResponseButton handleClick={() => setResponseModalVisible(true)} title="Это про меня" /> */}
          {/* <ResponseButton handleClick={() => setQuestionnaireModalVisible(true)} title="Анкета соискателя" /> */}
        </ButtonWrapper>
      </InformationWrapper>

      <ModalWindow
        isVisible={responseModalVisible || activeStatus || questionnaireModalVisible}
        onOverlayClick={handleOverlayClick}
        contentMaxWidth={questionnaireModalVisible ? 'clamp(0px, 95vw, 1088px)' : 'clamp(0px, 95vw, 704px)'}
      >
        {responseModalVisible && (
          <VacancyModal
            onClose={() => setResponseModalVisible(false)}
            onSubmit={handleSubmitVacancy}
            vacancyData={vacancy}
          />
        )}
        {activeStatus && <StatusModal onClose={() => setStatusVisible(false)} isSuccess={true} />}
        {questionnaireModalVisible && <QuestionnaireModalForm onClose={() => setQuestionnaireModalVisible(false)} />}
      </ModalWindow>
    </Wrapper>
  )
}

const mapStateToProps = (state: RootState) => ({
  activeStatus: state.statusModal.activeStatusModal
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setStatusVisible: (status: boolean) => dispatch(setStatusModalVisible(status))
})

type ContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & CardProps

export const VacancyCard = connect(mapStateToProps, mapDispatchToProps)(VacancyCardContainer)
