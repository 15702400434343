import React, { useEffect } from 'react'

import { Page } from 'app/templates'
import { breakpoints, useDimensions } from 'shared/dimensions'
import { Image, MobileUpperFrame, RoundButton, RoundButtonProps } from 'ui/components'
import styled from 'styled-components'
import { paths } from 'shared/routing'

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  height: 64px;
`

const CloseTerms = styled(RoundButton)`
  position: absolute;
  z-index: 3;
  top: 32px;
  right: 32px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    top: 20px;
    right: 20px;
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`

const SingleImage = styled(Image)`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: -1;
`

const ImageFilter = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;

  background: rgba(42, 61, 87, 0.9);
  mix-blend-mode: normal;
  z-index: -1;
`

const WidgetWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Widget: React.FC = () => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  console.log('window:', window)

  useEffect(() => {

    var i = setInterval(function () {

        // @ts-ignore
        if (window.city_widget && document.readyState === "complete") {
        // @ts-ignore
          if (isMobile) window.city_widget('240x400', '#widget-mobile')
        // @ts-ignore
          else window.city_widget('560x280', '#widget-desktop')

          clearInterval(i);
        }
      }, 50);

    // @ts-ignore
  
  })

  const widgetContent: JSX.Element = React.useMemo(
    () => (
      <Wrapper>
        <SingleImage name="career-bg.webp" width="100%" height="100%" fit="cover" />
        <ImageFilter />
        <HeaderWrapper>
          <CloseTerms {...RoundButtonProps} iconName="close" to={paths.projects} />
        </HeaderWrapper>
        {isMobile ? (
          <WidgetWrapper>
            <div id="widget-mobile" />
          </WidgetWrapper>
        ) : (
          <WidgetWrapper>
            <div id="widget-desktop" />
          </WidgetWrapper>
        )}
      </Wrapper>
    ),
    [isMobile]
  )

  return (
    <Page>
      {isMobile ? <MobileUpperFrame openingParameter={isMobile}>{widgetContent}</MobileUpperFrame> : widgetContent}
    </Page>
  )
}
