import { SubItemsT } from 'app/data/vacancy'
import React from 'react'

import { Text } from 'ui/components'
import { styled, theme } from 'ui/styles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`

const StyledText = styled(Text)`
  width: 100%;
  min-width: 200px;
  max-width: 450px;
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`

const StyledLi = styled.div<{ bulletSize?: string }>`
  display: flex;
  flex-direction: row;
  margin-top: 8px;

  &::before {
    content: '';
    display: block;
    flex-shrink: 0;
    width: ${({ bulletSize }) => (bulletSize ? bulletSize : '8px')};
    height: ${({ bulletSize }) => (bulletSize ? bulletSize : '8px')};
    border-radius: 50%;
    background-color: ${({ color }) => (color ? `${color}` : theme.palette.pink_red)};
    margin-right: 16px;
    margin-top: 8px;
  }
`
const SubItemsList = styled.ul`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  margin-left: 32px;
  margin-top: 8px;
  margin-bottom: 8px;
  counter-reset: list;
  font-size: 14px;
  line-height: 20px;
`

type SectionProps = {
  title: string;
  list: string[] | SubItemsT[];
}

export const Section: React.FC<SectionProps> = ({ title, list }) => (
  <Wrapper>
    <StyledText variant="t2" color={theme.palette.white}>
      {title}
    </StyledText>
    <ListWrapper>
      {list.map((el, i) => {
        if (typeof el === "string") {
          return (
            <StyledLi key={i} color={theme.palette.white}>
              <StyledText variant="t3" color={theme.palette.white}>
                {el}
              </StyledText>
            </StyledLi>
          )
        } 
        else {
          return (
            <>
              <StyledLi key={i} color={theme.palette.white}>
                <StyledText variant="t3" color={theme.palette.white}>
                  {el.text}
                </StyledText>
              </StyledLi>
              {el.subText && (
                <SubItemsList>
                  {el.subText.map((sub, j) => (
                    <StyledLi key={`${i}${j}`} color={theme.palette.white} bulletSize="3px">
                      <StyledText variant="t3" color={theme.palette.white}>
                        {sub}
                      </StyledText>
                    </StyledLi>
                  ))}
                </SubItemsList>
              )}
            </>
          )
        }
      })}
    </ListWrapper>
  </Wrapper>
)
