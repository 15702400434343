import { useState } from 'react'
import { useInput } from 'shared/hooks'
import { InputProps } from './useHardSkills'

export type TestingExperienceReturn = ReturnType<typeof useTestingExperience>

export const useTestingExperience = () => {
  const [radioValue, setRadioValue] = useState<{ [value: string]: string | undefined }>({ value: undefined })

  const onRadioChange = (value: string | undefined, name: string) => {
    setRadioValue((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const TEXTAREA_ROWS: InputProps = {
    typesOfTesting: {
      label: 'С какими видами тестирования приходилось сталкиваться в работе?',
      isOptional: true
    }
  }

  const RADIO_ROWS: InputProps = {
    testingTheory: {
      label:
        'Как ты оцениваешь свои знания теории тестирования (принципы тестирования, тест-дизайна и техники тест-дизайна)?'
    },
    manualTesting: {
      label: 'Как ты оцениваешь свои навыки в ручном тестировании пользовательского интерфейса?'
    },
    testingAPI: {
      label: 'Как ты оцениваешь свои навыки в тестировании API?'
    },
    autoTesting: {
      label: 'Как ты оцениваешь свои навыки в автотестировании?'
    }
  }

  const customRadioLabels = ['Низкий', 'Базовый', 'Выше среднего']

  const TEXTAREA_KEYS = Object.keys(TEXTAREA_ROWS)
  const RADIO_KEYS = Object.keys(RADIO_ROWS)

  TEXTAREA_ROWS.typesOfTesting.input = useInput({
    initialValue: TEXTAREA_ROWS.typesOfTesting.input?.value,
    isNotRequired: TEXTAREA_ROWS.typesOfTesting.isOptional
  })

  const isAllValid = TEXTAREA_KEYS.every((rowKey) => TEXTAREA_ROWS[rowKey].input?.isValid)

  const getValues = () => {
    const values = RADIO_KEYS.reduce<Record<string, string | undefined>>((res, rowKey) => {
      res[RADIO_ROWS[rowKey].label] = radioValue[RADIO_ROWS[rowKey].label]
      return res
    }, {})
    values[TEXTAREA_ROWS.typesOfTesting.label] = TEXTAREA_ROWS.typesOfTesting.input?.value

    return values
  }

  return {
    radioButtons: RADIO_ROWS,
    radioButtonsKeys: RADIO_KEYS,
    textAreas: TEXTAREA_ROWS,
    textAreasKeys: TEXTAREA_KEYS,
    radioLabels: customRadioLabels,
    onRadioChange,
    isAllValid,
    getValues
  }
}
