import React, { useCallback, useMemo, useState } from 'react'

import { useDimensions } from 'shared/dimensions'
import { ModalWindow } from 'shared/modal-window'
import { ContactUsModal, StatusModal } from 'app/features/modals'
import { PROJECTS } from 'app/data/projects'
import { SliderMobile } from 'app/features/slider/organisms/slider-mobile'
import { OutsourceOutstaffPopup } from 'app/features/modals/organisms/outsource-outstaff'
import { ProjectSlidePopupMobile } from 'app/features/slider/molecules/project-slide-popup-mobile'

import { Button, HBox, Icon, MobileUpperFrame, PrimaryButton, Text } from 'ui/components'
import { styled, theme } from 'ui/styles'
import { connect } from 'react-redux'
import { RootState } from 'root-reducer'

import { close, setActiveSection, setIsOpen } from 'store/project/popup/actions'
import { setStatusModalVisible } from 'store/status/actions'

const BREAKPOINT_2K = 2560

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  height: 100%;
  justify-content: space-between;
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
`

const StyledText = styled(Text)`
  margin-bottom: 12px;
`

const OpenModalButton = styled(Button)<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  transition: color ${theme.transition.hover}ms linear;
  color: ${({ isActive }) => (isActive ? theme.palette.dark_blue : theme.palette.pink_red)};
  margin-top: 20px;

  * svg * {
    stroke: ${({ isActive }) => (isActive ? theme.palette.dark_blue : theme.palette.pink_red)};
    transition: stroke ${theme.transition.hover}ms linear;
  }

  &:hover {
    color: ${theme.palette.dark_blue};
    * svg * {
      stroke: ${theme.palette.dark_blue};
    }
  }
`

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
`

const OrderButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const OrderButton = styled(PrimaryButton)`
  display: flex;
  width: 100%;

  @media (min-width: ${BREAKPOINT_2K - 1}px) {
    width: 283px;
  }
`

export const ProjectsContainer: React.FC<ContainerProps> = ({
  isPopupOpen,
  setIsPopupOpen,
  activeSection,
  activeStatus,
  setStatusVisible,
  setActiveProjectSection,
  closePopup
}) => {
  const [contactUsModalVisible, setContactUsModalVisible] = React.useState(false)

  const handleOverlayClick = () => {
    setContactUsModalVisible(false)
    setStatusVisible(false)
  }

  const handleSubmit = () => {
    setContactUsModalVisible(false)
    setStatusVisible(true)
  }

  const [isOpenMobileSlide, setIsOpenMobileSlide] = useState(false)
  const [currentSlide, setCurrentSlide] = useState<typeof PROJECTS[number]>(PROJECTS[0])

  const openSectionHandler = useCallback(
    (index: number) => {
      setIsPopupOpen(activeSection !== index)
      if (activeSection !== index) {
        setActiveProjectSection(index)
      } else {
        setActiveProjectSection(-1)
      }
    },
    [setIsPopupOpen, activeSection, setActiveProjectSection]
  )

  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  const jsxSliderMobile = useMemo(
    () =>
      isMobile && (
        <SliderMobile
          data={PROJECTS.map((el) => ({
            image: el.portfolio,
            title: el.title,
            chips: el.chips,
            onClick: () => {
              setCurrentSlide(el)
              setIsOpenMobileSlide(true)
            }
          }))}
        />
      ),
    [isMobile]
  )

  const closeSlidePopupMobile = useCallback(() => setIsOpenMobileSlide(false), [])

  return (
    <Wrapper>
      <MobileUpperFrame openingParameter={isOpenMobileSlide}>
        <ProjectSlidePopupMobile data={currentSlide} onClose={closeSlidePopupMobile} />
      </MobileUpperFrame>

      <MobileUpperFrame openingParameter={isPopupOpen}>
        <OutsourceOutstaffPopup closePopup={closePopup} activeSection={activeSection} />
      </MobileUpperFrame>

      <InfoWrapper>
        <StyledText variant={'h3'}>
          Более 8 лет разрабатываем
          <br />
          IT-продукты для бизнеса
        </StyledText>
        <OpenModalButton onClick={() => openSectionHandler(1)} isActive={activeSection === 1}>
          <Icon name="arrows/right" />
          <Text variant="h4">OUTSOURCE</Text>
        </OpenModalButton>
        <OpenModalButton onClick={() => openSectionHandler(2)} isActive={activeSection === 2}>
          <Icon name="arrows/right" />
          <Text variant="h4">OUTSTAFF</Text>
        </OpenModalButton>
      </InfoWrapper>

      <BottomSection>
        {jsxSliderMobile}
        {/* <OrderButtonWrapper>
          <OrderButton onClick={() => setContactUsModalVisible(true)}>Заказать проект</OrderButton>
          <HBox height={64} />
        </OrderButtonWrapper> */}
      </BottomSection>

      <ModalWindow
        isVisible={contactUsModalVisible || activeStatus}
        onOverlayClick={handleOverlayClick}
        contentMaxWidth="clamp(0px, 95vw, 704px)"
      >
        {activeStatus && <StatusModal onClose={() => setStatusVisible(false)} isSuccess={true} />}
        {contactUsModalVisible && (
          <ContactUsModal onClose={() => setContactUsModalVisible(false)} onSubmit={handleSubmit} />
        )}
      </ModalWindow>
    </Wrapper>
  )
}

const mapStateToProps = (state: RootState) => ({
  isPopupOpen: state.popup.isOpen,
  activeSection: state.popup.activeSection,
  activeStatus: state.statusModal.activeStatusModal
})

const mapDispatchToProps = (dispatch: any) => ({
  setIsPopupOpen: (isOpen: boolean) => dispatch(setIsOpen(isOpen)),
  setActiveProjectSection: (activeSection: number) => dispatch(setActiveSection(activeSection)),
  setStatusVisible: (status: boolean) => dispatch(setStatusModalVisible(status)),
  closePopup: () => dispatch(close())
})

export type ContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export const Projects = connect(mapStateToProps, mapDispatchToProps)(ProjectsContainer)
