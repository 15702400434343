import { useState } from 'react'
import { useInput } from 'shared/hooks'
import { validate } from 'shared/lib/validation'

import { InputProps } from './useSoftSkills'

export type OrganizationIssuesReturn = ReturnType<typeof useOrganizationIssues>

export const useOrganizationIssues = () => {
  const [checkboxValue, setCheckboxValue] = useState(false)
  const [radioValue, setRadioValue] = useState<string | undefined>('Я из Ярославля')

  const CHECKBOX_LABEL = 'Готов(а) выполнить тестовое задание?'
  const RADIO_LABEL = 'В каком формате готов(а) сотрудничать?'

  const onCheckboxChange = (value: boolean) => {
    setCheckboxValue(value)
  }

  const onRadioChange = (value: string | undefined, name: string) => {
    setRadioValue(value)
  }

  const RADIO_ROWS: InputProps = {
    workFormat: {
      label: 'В каком формате готов(а) сотрудничать?'
    }
  }

  const TEXTAREA_ROWS: InputProps = {
    salary: {
      label: 'Какие у тебя финансовые ожидания? От какой суммы готов(а) начать сотрудничество?',
      isOptional: true
    },
    gettingStarted: {
      label: 'В случае взаимного интереса и оффера когда сможешь приступить к работе в СODEITUP?',
      isOptional: true
    },
    remoteWork: {
      label: 'Могу работать только удаленно',
      placeholder: 'Укажите город',
      isOptional: true
    },
    portfolio: {
      label: 'Если у тебя есть резюме или портфолио, то приложи ссылки.',
      isOptional: true
    }
  }

  const customRadioLabels = ['Я из Ярославля', 'Я из другого города, но могу/хочу релоцироваться в Ярославль']

  const TEXTAREA_KEYS = Object.keys(TEXTAREA_ROWS)
  const RADIO_KEYS = Object.keys(RADIO_ROWS)

  TEXTAREA_ROWS.salary.input = useInput({
    initialValue: TEXTAREA_ROWS.salary.input?.value,
    validate: (val) => validate(val, 'number'),
    isNotRequired: TEXTAREA_ROWS.salary.isOptional
  })
  TEXTAREA_ROWS.gettingStarted.input = useInput({
    initialValue: TEXTAREA_ROWS.gettingStarted.input?.value,
    isNotRequired: TEXTAREA_ROWS.gettingStarted.isOptional
  })
  TEXTAREA_ROWS.remoteWork.input = useInput({
    initialValue: TEXTAREA_ROWS.remoteWork.input?.value,
    isNotRequired: TEXTAREA_ROWS.remoteWork.isOptional
  })
  TEXTAREA_ROWS.portfolio.input = useInput({
    initialValue: TEXTAREA_ROWS.portfolio.input?.value,
    isNotRequired: TEXTAREA_ROWS.portfolio.isOptional
  })
  const isAllValid = TEXTAREA_KEYS.every((rowKey) => TEXTAREA_ROWS[rowKey].input?.isValid)

  const getValues = () => {
    const textAreaValues = TEXTAREA_KEYS.reduce<Record<string, string | undefined | boolean>>((res, rowKey) => {
      res[TEXTAREA_ROWS[rowKey].label] = TEXTAREA_ROWS[rowKey].input!.value
      return res
    }, {})

    const radioValues = RADIO_KEYS.reduce<Record<string, string | undefined | boolean>>((res, rowKey) => {
      res[RADIO_ROWS[rowKey].label] = radioValue
      return res
    }, {})

    return [textAreaValues, radioValues]
  }

  return {
    checkboxValue,
    onCheckboxChange,
    onRadioChange,
    textAreas: TEXTAREA_ROWS,
    textAreasKeys: TEXTAREA_KEYS,
    CHECKBOX_LABEL,
    RADIO_LABEL,
    radioLabels: customRadioLabels,
    isAllValid,
    getValues
  }
}
