import { ContactUsSubmitT, VacancySubmitT } from 'app/features/modals/hooks'
import { DataFileT } from 'shared/hooks/useUploadFile'

import apiClient from '../http-common'

export type RequestModel = {
  id: string
}

export type RequestUploading = {
  name: string
  uuid: string
}

const createFeedbackApplication = async (data: ContactUsSubmitT) => {
  const response = await apiClient.post<RequestModel>('/feedbackApplications/', data)
  return response
}

const createCandidateApplication = async (data: VacancySubmitT) => {
  const response = await apiClient.post<RequestModel>('/candidateApplications/', data)
  return response
}

const uploadFiles = async (data: FileList) => {
  const formFiles = new FormData()
  Array.from(data).forEach((file) => {
    formFiles.append('files', file)
  })

  const response = await apiClient.post<DataFileT[]>('/uploadingFiles/', formFiles)
  return response
}

const HttpService = {
  createFeedbackApplication,
  createCandidateApplication,
  uploadFiles
}

export default HttpService
