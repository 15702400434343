import { getStyles, styled } from 'ui/styles'

export const NumberBulletLi = styled.li<{ counter?: string; color?: string }>`
  margin-top: 16px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  &::before {
    ${getStyles('t2')};
    font-weight: normal;
    color: ${({ theme, color }) => (color ? `${color}` : theme.palette.pink_red)};
    content: counter(${({ counter }) => (counter ? counter : 'list')}, normal) '. ';
    counter-increment: ${({ counter }) => (counter ? counter : 'list')};
    margin-right: 16px;
    display: block;
    align-self: flex-start;
  }
`
