import { ActionType, createReducer } from 'typesafe-actions'

import { PROJECTS } from 'app/data/projects'

import * as actions from './actions'

export type ProjectAction = ActionType<typeof actions>

type Project = typeof PROJECTS[number]

export type ProjectState = {
  activeProject: Project | null
  activeProjectIndex: number
}

const initialState: ProjectState = {
  activeProject: null,
  activeProjectIndex: -1
}

export const projectReducer = createReducer<ProjectState>(initialState).handleAction(
  actions.setProject,
  (state: ProjectState, { payload }: any) => {
    return { activeProject: PROJECTS[payload], activeProjectIndex: payload }
  }
)
