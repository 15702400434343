import { Link } from 'react-router-dom'
import { css } from 'styled-components'

import { styled } from 'ui/styles'

const styles = css<{ active: boolean; firstRender: boolean }>`
  display: inline-block;
  position: relative;

  ${({ active, theme }) => active && `color: ${theme.palette.pink_red};`}

  &::after {
    content: '';
    position: absolute;
    display: block;
    bottom: -18px;
    width: ${({ active, firstRender }) => (active && firstRender ? '100%' : 0)};
    height: 4px;
    left: 50%;
    right: 0;
    background: ${({ active, firstRender, theme }) =>
      firstRender && active ? theme.palette.dark_pink_red : theme.palette.blue_gray};
    transition: all ${({ theme }) => theme.transition.hover}ms linear;
    transform: translateX(-50%);
    opacity: 0;

    ${({ active, theme }) => active && `color: ${theme.palette.pink_red};`}
  }

  &:hover {
    &::after {
      width: 100%;
      opacity: 1;
    }
  }
`

const StyledLink = styled(Link)`
  ${styles}
`

const StyledDiv = styled.div`
  ${styles}
`

type Props = {
  isLink: boolean
  to?: string
  active: boolean
  firstRender: boolean
  forwardRef?: React.RefObject<HTMLAnchorElement | HTMLDivElement>
}

export const UnderlineTab: React.FC<Props> = ({ isLink, to = '/', forwardRef, ...props }) =>
  isLink ? (
    <StyledLink to={to} ref={forwardRef as React.RefObject<HTMLAnchorElement>} {...props} />
  ) : (
    <StyledDiv ref={forwardRef as React.RefObject<HTMLDivElement>} {...props} />
  )
