import { useState } from 'react'
import { InputProps } from './useHardSkills'

export type UsingToolsReturn = ReturnType<typeof useUsingTools>

export const useUsingTools = () => {
  const [radioValue, setRadioValue] = useState<{ [value: string]: string | undefined }>({ value: undefined })

  const onRadioChange = (value: string | undefined, name: string) => {
    setRadioValue((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const RADIO_ROWS: InputProps = {
    jira: {
      label: 'Jira'
    },
    linux: {
      label: 'Linux и работа с терминалом'
    },
    cicd: {
      label: 'CI/CD ( Hudson / Jenkins, TeamCity и др)'
    },
    confluence: {
      label: 'Confluence'
    },
    sql: {
      label: 'SQL и составление запросов'
    }
  }

  const RADIO_KEYS = Object.keys(RADIO_ROWS)

  const isAllValid = RADIO_KEYS.every((rowKey) => RADIO_ROWS[rowKey].input?.isValid)

  const getValues = () => {
    const values = RADIO_KEYS.reduce<Record<string, string | undefined>>((res, rowKey) => {
      res[RADIO_ROWS[rowKey].label] = radioValue[RADIO_ROWS[rowKey].label]
      return res
    }, {})

    return values
  }

  return {
    radioButtons: RADIO_ROWS,
    radioButtonsKeys: RADIO_KEYS,
    onRadioChange,
    isAllValid,
    getValues
  }
}
