import React from 'react'

import { styled, theme } from 'ui/styles'
import { Text, Separator, Link, RedButton } from 'ui/components'
import { Modal } from 'app/features/modals/templates'
import { breakpoints, useDimensions } from 'shared/dimensions'
import { paths } from 'shared/routing/paths'
import { VacancyT } from 'app/data/vacancy'

import { useVacancyModal, VacancyApiDataT, VacancyModalReturn, VacancySubmitT } from '../hooks'
import { FormRow } from '../molecules'
import { SendRequestReturnT, useSendRequest } from 'shared/hooks'

const POLICY_LINK = paths.terms

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

const Footer = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    flex-direction: column;
    margin-bottom: 32px;
  }
`

const StyledButton = styled(RedButton)`
  display: flex;
  justify-content: center;
  margin: 0 20px 0 0;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    margin: 0 0 8px 0;
  }
`

const StyledText = styled(Text)`
  margin: 8px 0;
`

const Header: React.FC<{ title: string }> = ({ title }) => (
  <>
    <Text variant="h3">{title ? 'Откликнуться на вакансию' : 'Хочу у вас работать'}</Text>
    {title && <Text variant="t2">{title}</Text>}
  </>
)

type Props = {
  onClose(): void
  onSubmit(): void
  vacancyData?: VacancyT
}

const OTHER_VACANCIES: {
  vacancyId: string
  vacancyType: string
}[] = JSON.parse(process.env.REACT_APP_OTHER_VACANCIES ?? '')

export const VacancyModal: React.FC<Props> = ({ onClose, onSubmit, vacancyData }) => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  const vacancyTitle: string = React.useMemo(() => (vacancyData ? vacancyData.title : ''), [vacancyData])
  const vacancyId: string = React.useMemo(
    () => (vacancyData ? vacancyData.vacancyId : OTHER_VACANCIES[0].vacancyId),
    [vacancyData]
  )
  const apiData: VacancyApiDataT = { vacancy: vacancyId, type: vacancyTitle }

  const vacancyModalData: VacancyModalReturn = useVacancyModal(apiData)

  const request: SendRequestReturnT = useSendRequest(onSubmit, true)

  const handleSendForm = () => {
    const data: VacancySubmitT = vacancyModalData.handleSubmit()

    if (Object.keys(data).length) {
      request.sendRequest(data)
    }
  }

  return (
    <Modal header={<Header title={vacancyTitle} />} onClose={onClose} isLoading={request.isLoading}>
      <StyledForm>
        {vacancyModalData.rowKeys.map((rowKey) => {
          const cell = vacancyModalData.rows[rowKey]
          return (
            <FormRow
              key={rowKey}
              label={cell.label}
              placeholder={cell.placeholder}
              input={cell.input!}
              isWithFile={cell.withFile}
              isPdfFile={cell.isPdfFile}
            />
          )
        })}
        {vacancyModalData.isInvalidLabelVisible && (
          <StyledText variant="t1" color={theme.palette.pink_red}>
            *Выделенные поля заполнены неверно.
          </StyledText>
        )}
        {request.isError && request.queryResult && (
          <StyledText variant="t1" color={theme.palette.pink_red}>
            Что-то пошло не так. Пожалуйста, повторите попытку
          </StyledText>
        )}
      </StyledForm>
      <Separator verticalMargin={24} />

      <Footer>
        <StyledButton big onClick={handleSendForm} disabled={!vacancyModalData.isButtonEnabled}>
          Отправить
        </StyledButton>
        <StyledText variant="t1" align={isMobile ? 'center' : 'left'}>
          Нажимая на кнопку, вы соглашаетесь с&nbsp;
          <Link href={POLICY_LINK} target="_blank">
            Обработкой персональных данных
          </Link>
        </StyledText>
      </Footer>
    </Modal>
  )
}
