import React from 'react'

import { useDimensions } from 'shared/dimensions'
import { styled } from 'ui/styles'
import { Checkbox, Text } from 'ui/components'

import { OrganizationIssuesReturn } from '../hooks/useOrganizationIssues'
import { CurrencyPickerRow, FormSection, SectionHeader } from '../atoms'
import { QuestionnaireFormRow } from '../atoms/form-row'
import { RadioGroup } from '../atoms/radio-group'

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const WorkCheckbox = styled.div`
  flex-direction: row;
  display: flex;
  align-items: start;
  width: 100%;
  margin-bottom: 24px;
`

const StyledText = styled(Text)`
  min-width: 240px;
  width: 100%;
`

const CheckboxWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: start;

  ${({ isMobile }) => (isMobile ? '' : 'width: 100%')};
`

export const OrganizationalIssuesSection: React.FC<OrganizationIssuesReturn> = ({
  checkboxValue,
  onCheckboxChange,
  onRadioChange,
  RADIO_LABEL,
  textAreas,
  textAreasKeys,
  CHECKBOX_LABEL,
  radioLabels
}) => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  const formFields = React.useMemo(
    () =>
      textAreasKeys.map((key, i) => (
        <FieldsWrapper key={key}>
          {i === 0 ? (
            <CurrencyPickerRow input={textAreas[key].input!} label={textAreas[key].label} onChange={onRadioChange} />
          ) : (
            <QuestionnaireFormRow
              label={textAreas[key].label}
              input={textAreas[key].input!}
              placeholder={textAreas[key].placeholder ?? ''}
            />
          )}
          {i === 1 && (
            <RadioGroup label={RADIO_LABEL} onChange={onRadioChange} customLabels={radioLabels} isAlwaysInColumn />
          )}
          {i === 2 && (
            <WorkCheckbox>
              <StyledText variant="h7">{CHECKBOX_LABEL}</StyledText>
              <CheckboxWrapper isMobile={isMobile}>
                <Checkbox value={checkboxValue} onChange={onCheckboxChange} />
              </CheckboxWrapper>
            </WorkCheckbox>
          )}
        </FieldsWrapper>
      )),
    [
      CHECKBOX_LABEL,
      RADIO_LABEL,
      checkboxValue,
      isMobile,
      onCheckboxChange,
      onRadioChange,
      radioLabels,
      textAreas,
      textAreasKeys
    ]
  )
  return (
    <FormSection>
      <SectionHeader>Организационные вопросы</SectionHeader>
      {formFields}
    </FormSection>
  )
}
