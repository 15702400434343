import React from 'react'
import styled from 'styled-components'

import { FormSection, SectionHeader } from '../atoms'
import { QuestionnaireFormRow } from '../atoms/form-row'
import { RadioGroup } from '../atoms/radio-group'
import { SoftSkillsReturn } from '../hooks/useSoftSkills'

const SoftSkillRow = styled.div`
  display: flex;
  flex-direction: column;
`

export const SoftSkillsSection: React.FC<SoftSkillsReturn> = ({
  radioButtons,
  radioButtonsKeys,
  textAreas,
  textAreasKeys,
  radioLabels,
  onRadioChange
}) => (
  <FormSection>
    <SectionHeader>Софт-скиллы и интересы</SectionHeader>
    <SoftSkillRow>
      {radioButtonsKeys.map((key) => (
        <RadioGroup key={key} label={radioButtons[key].label} onChange={onRadioChange} customLabels={radioLabels} />
      ))}
      {textAreasKeys.map((key) => (
        <QuestionnaireFormRow key={key} label={textAreas[key].label} input={textAreas[key].input!} />
      ))}
    </SoftSkillRow>
  </FormSection>
)
