import { ActionType, createReducer } from 'typesafe-actions'

import * as actions from './actions'
import { TagT } from 'app/data/vacancy'

export type TagState = {
  activeTag?: TagT
}

const initialState: TagState = {
  activeTag: undefined
}

export type TagAction = ActionType<typeof actions>

export const tagReducer = createReducer<TagState>(initialState).handleAction(
  actions.setActiveTag,
  (state: TagState, { payload }: any) => ({
    ...state,
    activeTag: payload
  })
)
