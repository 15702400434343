import React from 'react'

import { breakpoints, useDimensions } from 'shared/dimensions'
import styled from 'styled-components'
import { RedSpan, Text } from 'ui/components'

const Wrapper = styled.div`
  padding: 0 64px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding: 0 32px;
  }
`

export const Terms: React.FC = () => {
  const { isDesktop } = useDimensions()

  return (
    <Wrapper>
      <Text variant={isDesktop ? 'h3' : 'h5'}>
        Политика обработки
        <br />
        <RedSpan>персональных данных</RedSpan>
      </Text>
    </Wrapper>
  )
}
