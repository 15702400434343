import React from 'react'
import { connect } from 'react-redux'

import { styled, theme } from 'ui/styles'
import { HBox, Icon, PrimaryButton, Text } from 'ui/components'

import { VACANCY, TagT, VACANCY_KEYS } from 'app/data/vacancy'
import { ModalWindow } from 'shared/modal-window'
import { StatusModal, VacancyModal } from 'app/features/modals'
import { RootState } from 'root-reducer'
import { setActiveTag } from 'store/tag/actions'
import { setStatusModalVisible } from 'store/status/actions'

const BREAKPOINT_2K = 2560

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  height: 100%;
  justify-content: space-between;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ClearTagButton = styled.div`
  width: 100%;
`

const TagsList = styled.ul`
  margin: 32px 0;
  display: flex;
  flex-direction: column;
`

const Tag = styled.li<{ isActive: boolean }>`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? theme.palette.pink_red : 'inherit')};
  width: fit-content;
  transition: color ${theme.transition.hover}ms;
  gap: 4px;

  :not(:last-child) {
    margin-bottom: 16px;
  }

  * svg * {
    stroke: ${theme.palette.pink_red};
    transition: stroke ${theme.transition.hover}ms linear;
  }

  @media (hover: hover) {
    &:hover {
      color: ${theme.palette.dark_pink_red};
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const CareerButton = styled(PrimaryButton)`
  display: flex;
  width: 100%;

  @media (min-width: ${BREAKPOINT_2K - 1}px) {
    width: 283px;
  }
`

const CareerContainer: React.FC<ContainerProps> = ({ activeTag, setActiveVacancy, activeStatus, setStatusVisible }) => {
  const [vacancyModalVisible, setVacancyModalVisible] = React.useState(false)

  const vacancyTags = React.useMemo(
    () =>
      (VACANCY_KEYS as TagT[]).map((tag, i) => ({
        key: tag,
        value: i,
        label: VACANCY[tag][0].title
      })),
    []
  )

  const handleVacancyTagClick = (tag: TagT) => {
    if (tag in VACANCY) {
      if (activeTag === tag) {
        setActiveVacancy()
      } else {
        setActiveVacancy(tag)
      }
    }
  }

  const handleSubmitVacancy = () => {
    setVacancyModalVisible(false)
    setStatusVisible(true)
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <ClearTagButton onClick={() => setActiveVacancy()}>
          <Text variant="h3">Сейчас ищем</Text>
        </ClearTagButton>
        <TagsList>
          {vacancyTags.map((tag, i) => (
            <Tag key={i} isActive={activeTag === tag.key} onClick={() => handleVacancyTagClick(tag.key)}>
              <Icon name="arrows/right" />
              <Text variant="t1">{tag.label}</Text>
            </Tag>
          ))}
        </TagsList>
      </ContentWrapper>
      {/* <ButtonWrapper>
        <CareerButton onClick={() => setVacancyModalVisible(true)}>Хочу работать у вас</CareerButton>
        <HBox height={64} />
      </ButtonWrapper> */}
      <ModalWindow
        isVisible={vacancyModalVisible || activeStatus}
        onOverlayClick={() => {
          setVacancyModalVisible(false)
        }}
        contentMaxWidth="clamp(0px, 95vw, 704px)"
      >
        {activeStatus && <StatusModal onClose={() => setStatusVisible(false)} isSuccess={true} />}

        {vacancyModalVisible && (
          <VacancyModal onClose={() => setVacancyModalVisible(false)} onSubmit={handleSubmitVacancy} />
        )}
      </ModalWindow>
    </Wrapper>
  )
}

const mapStateToProps = (state: RootState) => ({
  activeTag: state.tag.activeTag,
  activeStatus: state.statusModal.activeStatusModal
})

const mapDispatchToProps = (dispatch: any) => ({
  setActiveVacancy: (tag?: TagT) => dispatch(setActiveTag(tag)),
  setStatusVisible: (status: boolean) => dispatch(setStatusModalVisible(status))
})

type ContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export const Career = connect(mapStateToProps, mapDispatchToProps)(CareerContainer)
