import { styled } from 'ui/styles'

export const Link = styled.a`
  color: ${({ theme }) => theme.palette.pink_red};
  transition: color ${({ theme }) => theme.transition.hover}ms linear;

  * {
    transition: stroke ${({ theme }) => theme.transition.hover}ms linear;
    stroke: ${({ theme }) => theme.palette.pink_red};
  }

  :hover {
    color: ${({ theme }) => theme.palette.dark_blue};
    text-decoration: underline;

    * {
      stroke: ${({ theme }) => theme.palette.dark_blue};
    }
  }
`
