import vacanciesJson from './vacancies.json'

export type TagT = keyof typeof vacanciesJson

export type SubItemsT = {
  text: string;
  subText: string[]
}

export type VacancyT = {
  vacancyId: string
  title: string
  tasks?: string[] | SubItemsT[]
  approach?: string[]
  expect?: string[]
  skills?: string[]
  desirable?: string[]
  conditions?: string[]
}

export const VACANCY: Record<string, VacancyT[]> = JSON.parse(JSON.stringify(vacanciesJson))

export const VACANCY_KEYS: string[] = Object.keys(VACANCY)
