import React from 'react'

import { TERMS } from 'app/data/terms'
import { RoundButtonProps, styled, theme } from 'ui/styles'
import { Text, NumberBulletLi, RoundButton } from 'ui/components'
import { breakpoints, useDimensions } from 'shared/dimensions'
import { paths } from 'shared/routing'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const StyledList = styled.ol`
  position: relative;
  counter-reset: list;
  overflow-y: auto;
  width: 100%;
  padding: 32px 0 46px 64px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding: 60px 32px 0 32px;
  }
`

const Title = styled(Text)`
  margin-top: 32px;
  max-width: 800px;
`

const Paragraph = styled(Text)`
  margin-top: 24px;
  max-width: 800px;
`

const LimitedSizeText = styled(Text)`
  max-width: 800px;
  width: 85%;
`

const CloseTerms = styled(RoundButton)`
  position: absolute;
  z-index: 3;
  top: 32px;
  right: 32px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    top: 20px;
    right: 20px;
  }
`

export const TermsContent: React.FC = () => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  return (
    <Wrapper>
      <StyledList>
        <HeaderWrapper>
          <LimitedSizeText variant={isMobile ? 'h2' : 'h3'} color={theme.palette.white}>
            Политика обработки персональных данных
          </LimitedSizeText>
          <CloseTerms {...RoundButtonProps} iconName="close" to={paths.projects} />
        </HeaderWrapper>
        {TERMS.map((term, i) => (
          <>
            <Title key={i} variant="t2" color={theme.palette.white}>
              {term.title}
            </Title>
            {term.list?.map((el, idx) => (
              <NumberBulletLi key={idx} color={theme.palette.white}>
                <LimitedSizeText variant="t1" color={theme.palette.white}>
                  {el}
                </LimitedSizeText>
              </NumberBulletLi>
            ))}
            {term.paragraphs?.map((el, idx) => (
              <Paragraph variant="t1" key={idx} color={theme.palette.white}>
                {el}
              </Paragraph>
            ))}
          </>
        ))}
      </StyledList>
    </Wrapper>
  )
}
