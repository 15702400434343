import { renderRoutes, RouteConfig } from 'react-router-config'

import { paths } from 'shared/routing'
import { Projects, Contacts, Career, Terms } from 'app'
import { NotFound, Widget } from 'app/pages'

const routes: RouteConfig[] = [
  {
    path: paths.projects,
    component: Projects,
    exact: true
  },
  {
    path: paths.contacts,
    component: Contacts,
    exact: true
  },
  {
    path: paths.career,
    component: Career,
    exact: true
  },
  {
    path: paths.terms,
    component: Terms,
    exact: true
  },
  {
    path: paths.widget,
    component: Widget,
    exact: true
  },
  {
    path: paths.notFound,
    component: NotFound,
    exact: true
  }
]

export const rootRoutes = () => renderRoutes(routes)
