import { keyframes } from 'styled-components'

export const showContentWithOpacity = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const slideText = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

export const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`
