import { useInput } from 'shared/hooks'
import { InputReturn } from 'shared/hooks/useInput'
import { DataFileT } from 'shared/hooks/useUploadFile'
import { validate } from 'shared/lib/validation'

type TWithFile = { withFile?: boolean; isPdfFile?: boolean }

type TRow = {
  label: string
  input?: InputReturn
  placeholder?: string
  isOptional?: boolean
}

const OTHER_VACANCIES: {
  vacancyId: string
  vacancyType: string
}[] = JSON.parse(process.env.REACT_APP_OTHER_VACANCIES ?? '')

type TRowsWithFile = Record<string, TRow & TWithFile>

const ROWS: TRowsWithFile = {
  lastName: {
    label: 'Фамилия*',
    placeholder: 'Константинов'
  },
  firstName: {
    label: 'Имя*',
    placeholder: 'Константин'
  },
  patronymic: {
    label: 'Отчество',
    placeholder: 'Константинович',
    isOptional: true
  },
  email: {
    label: 'Email*',
    placeholder: 'email@mail.ru'
  },
  phone: {
    label: 'Телефон*',
    placeholder: '+7 900 000-00-00'
  },
  portfolioLink: {
    label: 'Портфолио',
    placeholder: 'Ссылка или файл',
    withFile: true,
    isOptional: true
  },
  resume: {
    label: 'Резюме*',
    placeholder: 'Файл',
    withFile: true,
    isPdfFile: true
  },
  about: {
    label: 'Пару слов о себе',
    placeholder:
      'Добрый день. Меня заинтересовала работа в вашей компании, если мои скилы и опыт вас устроят, буду рад(а) пройти собеседование.',
    isOptional: true
  }
}

const ROW_KEYS = Object.keys(ROWS)

export type VacancyApiDataT = {
  vacancy: string
  type: string
}

export type VacancyModalReturn = ReturnType<typeof useVacancyModal>

export type VacancySubmitT = Record<string, string | DataFileT[]>

export const useVacancyModal = (data: VacancyApiDataT) => {
  ROWS.lastName.input = useInput({
    initialValue: ROWS.lastName.input?.value,
    validate: (val) => validate(val, 'russianName')
  })
  ROWS.firstName.input = useInput({
    initialValue: ROWS.firstName.input?.value,
    validate: (val) => validate(val, 'russianName')
  })
  ROWS.patronymic.input = useInput({
    initialValue: ROWS.patronymic.input?.value,
    isNotRequired: ROWS.patronymic.isOptional
  })
  ROWS.email.input = useInput({ initialValue: ROWS.email.input?.value, validate: (val) => validate(val, 'email') })
  ROWS.phone.input = useInput({
    initialValue: ROWS.phone.input?.value,
    validate: (val) => validate(val, 'phone'),
    isPhoneField: true
  })
  ROWS.portfolioLink.input = useInput({
    initialValue: ROWS.portfolioLink.input?.value,
    isNotRequired: ROWS.portfolioLink.isOptional
  })
  ROWS.resume.input = useInput({ initialValue: ROWS.resume.input?.value })
  ROWS.about.input = useInput({ initialValue: ROWS.about.input?.value, isNotRequired: ROWS.about.isOptional })

  const isInvalidLabelVisible = ROW_KEYS.some((rowKey) => !ROWS[rowKey].input?.isValid)

  const isButtonEnabled: boolean = ROW_KEYS.every(
    (row) => ROWS[row].isOptional || (ROWS[row].input!.isValid && (ROWS[row].input!.value || ROWS[row].input!.file))
  )

  const handleSubmit = (): VacancySubmitT => {
    let isAllValid = true

    const values = ROW_KEYS.reduce<VacancySubmitT>((result, rowKey) => {
      if (!ROWS[rowKey].isOptional && !ROWS[rowKey].input!.onBlur()) {
        isAllValid = false
        return result
      }
      if (ROWS[rowKey].input!.value) {
        result[rowKey] = ROWS[rowKey].input!.value
        ROWS[rowKey].input!.clearField()
      }

      return result
    }, {})

    values.type = data.type.length ? data.type : OTHER_VACANCIES[0].vacancyType
    values.vacancy = data.vacancy

    if (ROWS.portfolioLink.input!.file) {
      values.portfolioFile = ROWS.portfolioLink.input!.file
    }
    if (ROWS.resume.input!.file) {
      values.resume = ROWS.resume.input!.file
    }

    if (!isAllValid) {
      return {}
    }
    return values
  }

  return { rows: ROWS, rowKeys: ROW_KEYS, isInvalidLabelVisible, isButtonEnabled, handleSubmit }
}
